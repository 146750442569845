import { MDBCard, MDBCardHeader, MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import React, { useState, useEffect } from 'react'
import { Modal, AppBar } from '@material-ui/core';
import SignUp from './SignUp';
import Login from './Login.js';
import PropTypes from 'prop-types';
import jwt_decode from "jwt-decode";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ThemeButton from '../../auction/components/ThemeButton.js';
import theme from '../../auction/theme/Auction';

const useStyles = makeStyles(() => ({
    modalPaperCenter: {
        position: 'absolute',
        minWidth: '20rem',
        maxWidth: '350px',
        width: '350px',
        backgroundColor: '#00000066',
        boxShadow: '0 0 5px #fff',
        borderRadius: 6,
        color: '#ddd',
        padding: 0,
        margin: 0,
        outline: 'none',
        top: `20%`,
        left: `50%`,
        transform: `translate(-50%, 0%)`,

    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const StyledTabs = withStyles((theme) => ({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            width: '100%',
            backgroundColor: theme.palette.tertiary.main,
        },
    },
}))((props) => <Tabs {...props} variant='fullWidth' TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        backgroundColor: 'transparent',
        opacity: 1,
        minWidth: '30%',
        maxWidth: '50%',
        color: 'white',
        // fontSize: theme.typography.pxToRem(11),
        padding: 0,
        '&:hover': {
            color: theme.palette.tertiary.main,
            opacity: 1,
        },
        '&$selected': {
            color: theme.palette.tertiary.main,
        },
        '&:focus': {
            color: theme.palette.tertiary.main,
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

const LoginButton = (props) => {
    const { dispatch, state, logout } = props;
    const { t } = state;
    const classes = useStyles();

    const [values, setValues] = useState({
        modalOpen: false,
        mode: 'signin',
        tabValue: 0
    })

    const loginSuccess = (token, result) => {
        const decodedToken = jwt_decode(token);
        // Todo
        //bearer cookie with appropriate expire time
        console.log('dt:', decodedToken)
        console.log('result:', result)
        state.setCookie('bearer', token, { path: '/' });
        dispatch({
            type: 'setAll', payload: {
                username: decodedToken.username,
                _id: decodedToken._id,
                login: true
            }
        });
        setValues({
            ...values,
            modalOpen: false,
            tabValue: 0

        })
    }


    useEffect(() => {
        if (values.mode == '') {
            setValues({ ...values, modalOpen: false, mode: '' })
        }
    }, [values.mode])

    const handleChange = (event, newValue) => {
        setValues({
            ...values,
            tabValue: newValue
        });
    };

    const handleChangeIndex = (index) => {
        setValues({
            ...values,
            tabValue: index
        });
    };

    const modalBody = (<>
        <MDBRow className={'mx-0 ' + classes.modalPaperCenter}>
            <MDBCard className='bg-black px-0'>
                <MDBCardHeader className='p-0'>
                    <MDBRow className=' d-flex border-bottom border-dark' >
                        <MDBCol size='10'>
                            <AppBar position="static" color="transparent" className='border-0 shadow-0'>
                                {!state.loggedIn ?
                                    <StyledTabs value={values.tabValue} onChange={handleChange}>
                                        <StyledTab label="Login" />
                                        <StyledTab label="SignUp" />
                                    </StyledTabs> :
                                    <StyledTabs value={values.tabValue} onChange={handleChange}>
                                        <StyledTab label="Log Out" />
                                    </StyledTabs>}
                            </AppBar>
                        </MDBCol>
                        <MDBCol size='2' className='text-center pt-1'>
                            <MDBIcon onClick={() => setValues({ ...values, modalOpen: false, mode: '' })} className='pt-2 cursor-pointer' icon='times'></MDBIcon>
                        </MDBCol>
                    </MDBRow>
                </MDBCardHeader>

                <MDBRow>
                    <MDBCol className='pt-3'>
                        {!state.loggedIn ?
                            <SwipeableViews index={values.tabValue} onChangeIndex={handleChangeIndex}>
                                <TabPanel value={values.tabValue} index={0} >
                                    <Login state={state} onSuccess={loginSuccess} t={t}></Login>
                                </TabPanel>
                                <TabPanel value={values.tabValue} index={1} >
                                    <SignUp onSuccess={loginSuccess} mode={values.mode} t={t} dispatch={dispatch} state={state}></SignUp>
                                </TabPanel>
                            </SwipeableViews> :
                            <SwipeableViews index={values.tabValue} onChangeIndex={handleChangeIndex}>
                                <TabPanel value={values.tabValue} index={0} >
                                    <MDBRow className='py-3 mx-0 justify-content-center'>
                                        <MDBCol size='4' className='p-0'>
                                            <button onClick={() => { setValues({ ...values, modalOpen: false }); logout(state, dispatch) }} className='btn btn-neon btn-danger w-100 m-auto d-block'>{t('Log out')}</button>
                                        </MDBCol>
                                    </MDBRow>
                                </TabPanel>
                            </SwipeableViews>
                        }
                    </MDBCol>
                </MDBRow>
            </MDBCard>
        </MDBRow>
    </>);

    const accessDeny = () => {
        window.history.pushState({ page: "another" }, "Site", "/?view=GeoBlock");
        props.setValues({
            ...props.values,
            modalComponent: 'geoblock',
            showMenu: false
        })
    }

    return (<>

        {state.config.init ?
            <>
                <ThemeButton size='small' variant='outlined_' color='secondary' onClick={() => (state.config.countryAccess ? setValues({ ...values, modalOpen: true, mode: 'signin', tabValue: 0 }) : accessDeny())}>
                    <small>
                        Login / Register
                    </small>
                </ThemeButton>
                {/* {state.config.countryAccess ?
                    <Button size='small' variant='outlined' color='secondary' onClick={() => setValues({ ...values, modalOpen: true, mode: 'signin', tabValue: 0 })}>Login</Button>
                    : <Button className='border-white text-white' size='small' variant='outlined' color='secondary' onClick={accessDeny}>Access Blocked</Button>
                } */}

            </> : <></>
        }

        <Modal
            open={values.modalOpen}
            disableScrollLock={true}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    setValues({ ...values, modalOpen: false, mode: '' })
                }
            }}>
            {modalBody}
        </Modal>
    </>
    )
}



export default LoginButton
