import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';

import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';


import React, { useEffect, Fragment } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { MDBIcon } from 'mdb-react-ui-kit';
import { List } from '@material-ui/core';
import SideNavData from './../../auction/Layout/SideNavData'

import $ from 'jquery'

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
        paddingTop: '10px',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
        [theme.breakpoints.up('sm')]: {
            position: 'static'
        },
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 0, //theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            // width: theme.spacing(7) + 1
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        color: theme.palette.default.contrastText,
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    chevron: {
        color: theme.palette.default.contrastText,
        paddingRight: 5
    },
    listItem: {
        borderLeft: '0.2rem solid transparent',
        '&.active': {
            borderLeft: '0.2rem solid #f4b740'
        }
    },
    listItemIcon: {
        fontSize: '1.2rem',
        color: '#fff',
        minWidth: theme.spacing(5)
        //  "& .MuiSvgIcon-root": { fontSize: "1.2rem" }
    },
    listSubItemLine: {
        borderTop: '1px solid white',
        width: 5
    },
    listSubItemText: {
        '& span': {
            paddingLeft: 5,
            //   fontSize: '0.8em',
            [theme.breakpoints.down('sm')]: {
                //       fontSize: '1em',
            }
        }
    },
    listItemText: {
        "& span": {
            //  fontFamily: 'Noto Sans JP, sans-serif',
            //    fontSize: '0.9em',
            [theme.breakpoints.down('sm')]: {
                //    fontSize: '1.1rem',
            }
        }
    },

}));


const SideNav = (props) => {
    const { renderComponent, setRenderComponent, handleDrawerOpen, handleDrawerClose, drawerOpen, state } = props;
    const classes = useStyles();
    const theme = useTheme();

    useEffect(() => {
        if (!drawerOpen) {
            $(`#DrawerMenuItems [data-id]`).slideUp();
            $(`#DrawerMenuItems [data-item]`).find('[class*=fa-chevron-]*').addClass('fa-chevron-left').removeClass('fa-chevron-down');
        }
    }, [drawerOpen])

    const toggleDrawerItem = (item) => {
        const open = $(`[data-item="${item}"]`).find('.fa-chevron-left').length;
        $(`#DrawerMenuItems [data-id="${item}"]`).slideToggle();
        $(`#DrawerMenuItems [data-item]`).removeClass('active');
        $(`#DrawerMenuItems [data-item="${item}"]`).addClass('active');
        $(`#DrawerMenuItems [data-item="${item}"]`).find('[class*=fa-chevron-]*').toggleClass('fa-chevron-left fa-chevron-down');
        $(`#DrawerMenuItems [datasubitem]`).removeClass('active');
        handleDrawerOpen()
        // if (open) {
        // }
    }

    const setRenderComponentFunc = (sub, item, renderComponent) => {
        setRenderComponent(renderComponent);
        console.log(renderComponent)
        $(`#DrawerMenuItems [data-item]`).removeClass('active');
        $(`#DrawerMenuItems [data-item='${item}']`).addClass('active');
        $(`#DrawerMenuItems [datasubitem]`).removeClass('active');
        $(`#DrawerMenuItems [datasubitem='${sub}']`).addClass('active');
    }

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: drawerOpen,
                [classes.drawerClose]: !drawerOpen,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: drawerOpen,
                    [classes.drawerClose]: !drawerOpen,
                }),
            }}>
            <div className={classes.toolbar}>
                <IconButton className={classes.chevron} onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </div>
            <Divider />
            <List id='DrawerMenuItems'>
                {SideNavData.map((item, index) => (
                    <Fragment key={index} >
                        <ListItem data-item={item.text} button className={classes.listItem} onClick={() => { toggleDrawerItem(item.text) }}>
                            <ListItemIcon className={classes.listItemIcon}>
                                <MDBIcon icon={item.icon}></MDBIcon>
                            </ListItemIcon>
                            <ListItemText className={classes.listItemText} primary={item.text} />
                            {
                                item.subItems.length > 0 ?
                                    <MDBIcon className={classes.chevron} icon='chevron-left'></MDBIcon>
                                    : <></>
                            }
                        </ListItem>
                        <div data-id={item.text} style={{ display: 'none' }}>
                            {(item.subItems).map((subItem, index) => (
                                <Fragment key={index} >
                                    <ListItem datasubitem={subItem.text} datasubitem-item={item.text} button dense={true} className='px-0 py-0 adminPanelDrawerSubItem' onClick={() => { setRenderComponentFunc(subItem.text, item.text, subItem.component) }}>
                                        {/* <MDBIcon icon='circle' style={{ fontSize: '0.25rem', opacity: 0.7 }}></MDBIcon> */}
                                        <span className={classes.listSubItemLine}></span>
                                        <ListItemText primary={subItem.text} className={classes.listSubItemText} />
                                    </ListItem>
                                </Fragment>
                            ))}
                        </div>
                        {/* <Divider className='bg-dark' /> */}
                    </Fragment>
                ))
                }
            </List>
        </Drawer>

    )
}

export default SideNav
