import React, { useEffect, useState } from 'react'
import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import AuctionAccordion from './components/Accordion/AuctionAccordion'
import ImageSlider from './components/ImageSlider.js/ImageSlider'
import AuctionSection from './components/AuctionSection/AuctionSection'
import { useMediaQuery } from '@material-ui/core'
import UserSection from './components/UserSection/UserSection'

const lots = [
    {
        _id: '1',
        title: '2008 KOMATSU PC1250-8R Hydraulic Excavator',
        image: 'https://www.rbauction.com/equipment_images/2021256/large/12711706_1.jpg',
        // time: '1628323746500',
        time: '1628333746500',
        open: true,
        lastBid: 107000,
        maxBid: 108000,
        general: `3400 mm stick, A/C, Please click on the following link to see the short video: https://youtu.be/1I6so5K9q38`,
        technical: <div>Make: KOMATSU<br />Model:PC1250-8R<br />Year:2008<br />Serial No.:KMTPC190A0203XXXX<br />Meter reads (unverified)55581 Hr</div>,
        payment: `**GCC DUTIES NOT PAID** **The closing times of Lots 486,487 and 488 are tied together. If a bid is received, a time-extension will be applied to all lots. Once the time closes, the highest bidder on each piece will be the winner** Closing Time: 2021-06-29 02:27:20 PM`,
        images: [
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_1.jpg`,
                title: 'Image 1'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_2.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_3.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_4.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_5.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_6.jpg`,
                title: 'Image 2'
            }, {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_1.jpg`,
                title: 'Image 1'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_2.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_3.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_4.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_5.jpg`,
                title: 'Image 2'
            }, {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_1.jpg`,
                title: 'Image 1'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_2.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_3.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_4.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_5.jpg`,
                title: 'Image 2'
            }, {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_1.jpg`,
                title: 'Image 1'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_2.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_3.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_4.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_5.jpg`,
                title: 'Image 2'
            }, {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_1.jpg`,
                title: 'Image 1'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_2.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_3.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_4.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_5.jpg`,
                title: 'Image 2'
            }, {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_1.jpg`,
                title: 'Image 1'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_2.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_3.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_4.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_5.jpg`,
                title: 'Image 2'
            }, {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_1.jpg`,
                title: 'Image 1'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_2.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_3.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_4.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_5.jpg`,
                title: 'Image 2'
            }, {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_1.jpg`,
                title: 'Image 1'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_2.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_3.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_4.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_5.jpg`,
                title: 'Image 2'
            }, {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_1.jpg`,
                title: 'Image 1'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_2.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_3.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_4.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_5.jpg`,
                title: 'Image 2'
            }, {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_1.jpg`,
                title: 'Image 1'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_2.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_3.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_4.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_5.jpg`,
                title: 'Image 2'
            }, {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_1.jpg`,
                title: 'Image 1'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_2.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_3.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_4.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12711706_5.jpg`,
                title: 'Image 2'
            },
        ]
    },
    {
        _id: '2',
        title: '2009 TADANO GR700EX 70 Ton 4x4x4 Rough Terrain Crane',
        image: 'https://www.rbauction.com/equipment_images/2021256/large/12707576_1.jpg',
        lastBid: 163000,
        maxBid: 164000,
        time: '1628355746500',

        general: `3400 mm stick, A/C, Please click on the following link to see the short video: https://youtu.be/1I6so5K9q38`,
        technical: <div>Make: TADANO<br />Model: GR700EX 70 Ton 4x4x4<br />Year: 2009<br />Serial No.: 54XXXX<br />Meter reads (unverified) 1549 Hr</div>,
        payment: `**GCC DUTIES NOT PAID** **The closing times of Lots 486,487 and 488 are tied together. If a bid is received, a time-extension will be applied to all lots. Once the time closes, the highest bidder on each piece will be the winner** Closing Time: 2021-06-29 02:27:20 PM`,
        images: [
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12707576_1.jpg`,
                title: 'Image 1'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12707576_2.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12707576_3.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12707576_4.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12707576_5.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021256/large/12707576_6.jpg`,
                title: 'Image 2'
            }
        ]
    },
    {
        _id: '3',
        title: '2014 KOMATSU WA600-6 Wheel Loader',
        image: 'https://www.rbauction.com/equipment_images/2021122/large/12599601_1.jpg',
        lastBid: 142000,
        maxBid: 142500,
        time: '1628356746500',
        general: `3400 mm stick, A/C, Please click on the following link to see the short video: https://youtu.be/1I6so5K9q38`,
        technical: <div>Make: KOMATSU <br />Model: PC1250-8R<br />Year: 2008<br />Serial No.: KMTPC190A0203XXXX<br />Meter reads (unverified) 55581 Hr</div>,
        payment: `**GCC DUTIES NOT PAID** **The closing times of Lots 486,487 and 488 are tied together. If a bid is received, a time-extension will be applied to all lots. Once the time closes, the highest bidder on each piece will be the winner** Closing Time: 2021-06-29 02:27:20 PM`,
        images: [
            {
                url: `https://www.rbauction.com/equipment_images/2021122/large/12599601_1.jpg`,
                title: 'Image 1'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021122/large/12599601_2.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021122/large/12599601_3.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021122/large/12599601_4.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021122/large/12599601_5.jpg`,
                title: 'Image 2'
            },
            {
                url: `https://www.rbauction.com/equipment_images/2021122/large/12599601_6.jpg`,
                title: 'Image 2'
            }
        ]
    }
]

const Auction = (props) => {
    const desktop = useMediaQuery('(min-width:600px)');

    const [selectedLot, setSelectedLot] = useState({});

    useEffect(() => {
        if (desktop) {
            setSelectedLot(lots[0])
        }
    }, [desktop])

    return (
        <MDBRow className='pt-0 m-0'>
            <MDBCol size='12' md='4' className='d-none d-md-block overflow-scroll noScrollbar' style={{ maxHeight: 'calc(100vh - 6rem)', minHeight: 'calc(100vh - 6rem)' }}>
                <ImageSlider {...selectedLot}></ImageSlider>
                <AuctionAccordion {...selectedLot}></AuctionAccordion>
            </MDBCol>
            <MDBCol size='12' md='4' className='px-1 overflow-hidden ' style={{ maxHeight: 'calc(100vh - 6rem)', minHeight: 'calc(100vh - 6rem)' }}>
                {/* <ThemeCard></ThemeCard> */}
                <AuctionSection selectedLot={selectedLot} setSelectedLot={setSelectedLot} lots={lots.concat(lots).concat(lots).concat(lots)}></AuctionSection>
            </MDBCol>
            <MDBCol size='12' md='4'>
                <UserSection selectedLot={selectedLot} setSelectedLot={setSelectedLot} lots={lots}></UserSection>
            </MDBCol>
        </MDBRow>
    )
}

export default Auction
