import React from 'react';
import { FormControl, InputLabel, makeStyles, MenuItem, Select, withStyles } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles((theme) => ({

    label: {
        color: '#fff',
        '&.Mui-focused': {
            color: theme.palette.secondary.light + ' !important'
        },
    },
    formControl: {
        '& svg': {
            fill: theme.palette.primary.contrastText
        }
    },
    start: {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 99
    },
    end: {
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 99
    }
}));


const BootstrapSelect = withStyles((theme) => ({
    root: {
        boxShadow: 'none',
        'label + &': {
            marginTop: theme.spacing(2),
        },
        '&.noBorder input': {
            border: '1px solid #ffffff33',
            color: '#bbb'
        },
        '&.noBorder textarea': {
            border: '1px solid #ffffff33',
            color: '#bbb'
        },
        '&.MuiInputBase-multiline': {
            padding: 0
        },

    },
    select: {
        borderRadius: 4,
        color: '#fff',
        position: 'relative',
        backgroundColor: 'transparent',
        border: '1px solid #ffffff',
        fontSize: 16,
        padding: '5px 6px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderColor: theme.palette.secondary.light,
        }
    }
}))(Select);

const FR_Select = (props) => {
    const { id, name, label, isDisabled,
        className, labelClassName,
        options,
        // startAdornment, endAdornment,
        endAdornmentInside, startAdornmentInside } = props;
    const classes = useStyles();


    return (
        <FormControl className={classes.formControl + ' w-100 ' + className + ' position-relative overflow-hidden rounded-2'}>
            {label ?
                <InputLabel className={classes.label + ' ' + labelClassName} shrink htmlFor={id ? id : name}>
                    {label}
                </InputLabel> : <></>}
            {endAdornmentInside ?
                <div className={classes.end}>
                    {endAdornmentInside}
                </div> : <></>
            }
            {startAdornmentInside ?
                <div className={classes.start}>
                    {startAdornmentInside}
                </div> : <></>
            }
            <BootstrapSelect
                id={id ? id : name}
                //startAdornment={startAdornment}
                //endAdornment={endAdornment}
                disableUnderline={true}
                {...props}
                className={(isDisabled ? 'noBorder' : '') + ' noArrow w-100 ' + className}>
                {/* {options.map((option, i) =>
                    <MenuItem value={option.value}>{option.label}</MenuItem>
                )} */}
            </BootstrapSelect>
        </FormControl>
    )
}

export default FR_Select
