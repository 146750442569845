const Reducer = (state, action) => {
    switch (action.type) {
        case 'setAll':
            return {
                ...state,
                ...action.payload
            };
        case 'setConfig':
            return {
                ...state,
                config: action.payload
            };
        case 'setLogin':
            return {
                ...state,
                login: action.payload
            };
        case 'setUsername':
            return {
                ...state,
                username: action.payload
            };
        default: return {
            ...state
        }
    }
};

export default Reducer;