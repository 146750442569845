import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import theme from '../theme/Auction';
import { MDBIcon } from 'mdb-react-ui-kit';

const styles = {
    root: {
        marginLeft: 'auto',
        background: theme.gradient.secondary,
        border: 'none !important',
        outline: 'none',
        borderRadius: '3px',
        padding: 0,
        fontWeight: 900,
        '& > span.MuiButton-label': {
            fontSize: '0.8rem',
            color: '#000',
            padding: '3px 10px',
            // backgroundColor: theme.palette.primary.dark,
            borderRadius: '3px',
            display: 'inline-block',
            margin: '1px',
        }
    },
    rootOutlined: {
        '& > span.MuiButton-label': {
            color: '#fff',
            backgroundColor: '#000'//theme.palette.primary.dark,
        }
    }
};

function ClassNames(props) {
    const { classes, children, className, variant, waiting, ...other } = props;
    return (
        <Button className={clsx(classes.root, (variant == 'outlined' ? classes.rootOutlined : ''), className)} {...other}>
            {waiting ?
                <MDBIcon spin icon='sync'></MDBIcon> :
                <>   {children || 'class names'} </>
            }
        </Button>
    );
}

ClassNames.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

export default withStyles(styles)(ClassNames);
