import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import CloseIcon from '@material-ui/icons/Close';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ThemeButton from '../../ThemeButton';
import FR_Select from '../../FormRenderer/FormComponents/FR_Select';
import { IconButton, Toolbar, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    input: {
        width: '',
        background: '#1D1E2E',
        color: '#fff',
        borderRadius: '6px',
        fontSize: '0.8em',
        padding: '5px 10px',
        border: 'none',
        // fontFamily: 'karla',
        fontWeight: 'normal',
        boxShadow: 'inset -1px -1px 1px 0px #fff5'
    },
    fab: {
        width: '40px',
        height: '40px',
    },
    filter: {
        position: 'absolute',
        top: '42%',
        left: '46%',
        fontSize: '0.6em'
    }
}));
const UpcomingSearch = () => {
    const classes = useStyles();
    const [category, setCategory] = useState({
        open: false,
        cat: 'All',
        subCat: 'All',
        sort: ''
    })

    return (
        <MDBRow className='m-0'>
            <MDBCol size='10' className='pt-2 pe-0 mt-1'>
                <input placeholder='Type Something' className={classes.input + ' w-100 d-inline-block'}></input>
            </MDBCol>
            <MDBCol size='2' className='p-0 mt-n1'>
                <span onClick={() => setCategory({ ...category, open: true })} className='position-relative cursor-pointer' style={{ width: '70px', height: '70px' }}>
                    <ICON className='position-absolute_'></ICON>
                    <MDBIcon className={classes.filter} icon='filter'></MDBIcon>
                </span>
            </MDBCol>
            <MDBCol size='12'>
                {/* <ThemeButton variant='outlined' onClick={() => setCategory({ ...category, open: true })}>{category.cat} - {category.subCat}</ThemeButton> */}
                <SelectCategory category={category} setCategory={setCategory}></SelectCategory>
            </MDBCol>
        </MDBRow>

    )
}

const ICON = (props) => {
    return <svg className={props.className} width="55" height="55" viewBox="0 0 66 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_dd)">
            <path fillRule="evenodd" clipRule="evenodd" d="M35.5 51C44.3366 51 51.5 43.8366 51.5 35C51.5 26.1634 44.3366 19 35.5 19C26.6634 19 19.5 26.1634 19.5 35C19.5 43.8366 26.6634 51 35.5 51Z" fill="#28293D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M35.5 51C44.3366 51 51.5 43.8366 51.5 35C51.5 26.1634 44.3366 19 35.5 19C26.6634 19 19.5 26.1634 19.5 35C19.5 43.8366 26.6634 51 35.5 51Z" fill="url(#paint0_linear)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M35.5 51C44.3366 51 51.5 43.8366 51.5 35C51.5 26.1634 44.3366 19 35.5 19C26.6634 19 19.5 26.1634 19.5 35C19.5 43.8366 26.6634 51 35.5 51Z" stroke="#3D3A44" />
            <path fillRule="evenodd" clipRule="evenodd" d="M35.5 51C44.3366 51 51.5 43.8366 51.5 35C51.5 26.1634 44.3366 19 35.5 19C26.6634 19 19.5 26.1634 19.5 35C19.5 43.8366 26.6634 51 35.5 51Z" stroke="url(#paint1_linear)" />
        </g>
        <defs>
            <filter id="filter0_dd" x="0" y="0.5" width="66" height="67" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dx="2" dy="4" />
                <feGaussianBlur stdDeviation="6" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.169583 0 0 0 0 0.174167 0 0 0 0 0.275 0 0 0 1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dx="-3" dy="-2" />
                <feGaussianBlur stdDeviation="8" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.305937 0 0 0 0 0.310571 0 0 0 0 0.4125 0 0 0 1 0" />
                <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
            </filter>
            <linearGradient id="paint0_linear" x1="51.5" y1="19" x2="13.7359" y2="44.8977" gradientUnits="userSpaceOnUse">
                <stop stopColor="#74768C" />
                <stop offset="1" stopColor="#1D1E2E" />
            </linearGradient>
            <linearGradient id="paint1_linear" x1="51.5" y1="19" x2="13.7359" y2="44.8977" gradientUnits="userSpaceOnUse">
                <stop stopColor="#45475F" />
                <stop offset="1" stopColor="#28293D" />
            </linearGradient>
        </defs>
    </svg>
}

const SelectCategory = (props) => {
    const { category, setCategory } = props;

    const useStyles = makeStyles((theme) => ({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            '& select': {
                marginTop: 3
            },
            '& label': {
                paddingBottom: 3
            },
            '& svg': {
                fill: '#000'
            }
        },
        title: theme.gradientText.secondary,
        formControl: {
            margin: theme.spacing(1),
            marginTop: 0,
            minWidth: 120,
            '& select': {
                background: theme.gradient.secondary,
                borderRadius: 4,
                color: '#000',
                position: 'relative',
                backgroundColor: 'transparent',
                // border: '1px solid #ffffff',
                fontSize: 16,
                padding: '5px 6px',
                transition: theme.transitions.create(['border-color', 'box-shadow']),
                '&:focus': {
                    // borderColor: theme.palette.secondary.light,
                }
            }
        },
    }));

    const handleClose = () => {
        setCategory({
            ...category,
            open: false
        });
    };
    const classes = useStyles();

    return (
        <div>

            <Dialog open={category.open} onClose={handleClose}>
                <Toolbar className='justify-content-between'>
                    <Typography variant="h6" className={classes.title}>
                        Select Category
                    </Typography>
                    <IconButton className='p-0 m-0' edge="end" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon className='mt-n3 me-n3' />
                    </IconButton>
                </Toolbar>
                {/* <DialogTitle>Select Category
                    <IconButton className='float-end' edge="end" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle> */}
                <DialogContent className='py-0 my-0'>
                    <form className={classes.container}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="demo-dialog-native">Category</InputLabel>
                            <Select
                                native
                                value={category.cat}
                                disableUnderline={true}
                                onChange={cat => setCategory({ ...category, cat: cat.target.value })}
                            // input={<Input  />}
                            >
                                <option aria-label="All" value="All"></option>
                                <option value='Excavator'>Excavator</option>
                                <option value='Truck'>Truck</option>
                                <option value='Dump'>Dump</option>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-dialog-select-label">Sub Category</InputLabel>
                            <Select
                                disableUnderline={true}
                                native
                                labelId="demo-dialog-select-label"
                                id="demo-dialog-select"
                                value={category.subCat}
                                onChange={subcat => setCategory({ ...category, subCat: subcat.target.value })}
                            //  input={<Input />}
                            >
                                <option aria-label="All" value="All"></option>
                                <option value='a'>a</option>
                                <option value='b'>b</option>
                                <option value='c'>c</option>
                            </Select>
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogContent className='py-0 my-0'>
                    <Typography variant="h6" className={classes.title + ' d-inline-block  mt-3'}>
                        Sort By
                    </Typography>
                    <form className={classes.container + ' display-block'}>
                        <FormControl className={classes.formControl + ' w-100'}>
                            {/* <InputLabel htmlFor="demo-dialog-native">Category</InputLabel> */}
                            <Select
                                native
                                value={category.sort}
                                disableUnderline={true}
                                onChange={sort => setCategory({ ...category, sort: sort.target.value })}
                            // input={<Input  />}
                            >
                                <option aria-label="All" value="All"></option>
                                <option value='Lot (from first)'>Lot (from first)</option>
                                <option value='ighest Bids'>Highest Bids</option>
                                <option value='Dump'>Dump</option>
                            </Select>
                        </FormControl>
                        {/* <FormControl className={classes.formControl}>
                            <InputLabel id="demo-dialog-select-label">Sub Category</InputLabel>
                            <Select
                                disableUnderline={true}
                                native
                                labelId="demo-dialog-select-label"
                                id="demo-dialog-select"
                                value={category.subCat}
                                onChange={subcat => setCategory({ ...category, subCat: subcat.target.value })}
                            //  input={<Input />}
                            >
                                <option aria-label="All" value="All"></option>
                                <option value='a'>a</option>
                                <option value='b'>b</option>
                                <option value='c'>c</option>
                            </Select>
                        </FormControl> */}
                    </form>
                </DialogContent>
                <DialogContent className='py-0 my-0'>
                    <Typography variant="h6" className={classes.title + ' d-inline-block mt-3'}>
                        Select Country
                    </Typography>
                    <form className={classes.container + ' display-block'}>
                        <FormControl className={classes.formControl + ' w-100'}>
                            {/* <InputLabel htmlFor="demo-dialog-native">Category</InputLabel> */}
                            <Select
                                native
                                value={category.sort}
                                disableUnderline={true}
                                onChange={sort => setCategory({ ...category, sort: sort.target.value })}
                            // input={<Input  />}
                            >
                                <option aria-label="All" value="All"></option>
                                <option value='Lot (from first)'>Lot (from first)</option>
                                <option value='ighest Bids'>Highest Bids</option>
                                <option value='Dump'>Dump</option>
                            </Select>
                        </FormControl>
                        {/* <FormControl className={classes.formControl}>
                            <InputLabel id="demo-dialog-select-label">Sub Category</InputLabel>
                            <Select
                                disableUnderline={true}
                                native
                                labelId="demo-dialog-select-label"
                                id="demo-dialog-select"
                                value={category.subCat}
                                onChange={subcat => setCategory({ ...category, subCat: subcat.target.value })}
                            //  input={<Input />}
                            >
                                <option aria-label="All" value="All"></option>
                                <option value='a'>a</option>
                                <option value='b'>b</option>
                                <option value='c'>c</option>
                            </Select>
                        </FormControl> */}
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button size='small' className='bg-danger py-1' color='success'>
                        Reset
                    </Button>
                    <Button  size='small' className='bg-success py-1' color='success'>
                        Filter
                    </Button>
                    {/* <ThemeButton className='bg-danger' onClick={handleClose} color="primary">
                        Reset
                    </ThemeButton>
                    <ThemeButton variant='outlined_' onClick={handleClose} color="primary">
                        Filter
                    </ThemeButton> */}
                </DialogActions>
            </Dialog>
        </div>
    );

}


export default UpcomingSearch
