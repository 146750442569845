import React from 'react';
import ReactDOM from 'react-dom';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import './styles/index.scss';
import App from './App';
import './i18n';
// import { SocketContext, socket, apiHost } from './context/socket';
import { CookiesProvider } from 'react-cookie';
import Store from './store';
import { Slide } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

// import reportWebVitals from './reportWebVitals';
// window.apiHost = apiHost;

ReactDOM.render(
  <Store>
    <CookiesProvider>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        TransitionComponent={Slide}
        maxSnack={2}>
        <App />
      </SnackbarProvider>
    </CookiesProvider>
  </Store>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


// <React.StrictMode>
//     <CookiesProvider>
//       <SocketContext.Provider value={socket}>
//         <App />
//       </SocketContext.Provider>
//     </CookiesProvider>
//   </React.StrictMode>