import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: theme.palette.primary.dark
    },
    dialog: {
        // background: theme.gradient.primary
    },
    title: {
        ...theme.gradientText.secondary,
        // marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullPageModal = (props) => {
    const { open, setOpen, className } = props;
    const classes = useStyles();

    return (<Dialog className={classes.dialog + ' ' + className} fullScreen open={open} onClose={() => setOpen(false)} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
            <Toolbar>
                <Typography variant="subtitle2" className={classes.title}>
                    {props.title || 'Title'}
                </Typography>
                <IconButton edge="end" color="inherit" onClick={() => setOpen(false)} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
        {props.children}
    </Dialog>
    );
}

export default FullPageModal
