import React from 'react';
import { FormControl, InputLabel, makeStyles, withStyles } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles((theme) => ({
    label: {
        color: '#fff',
        '&.Mui-focused': {
            color: theme.palette.secondary.light + ' !important'
        },
    },
    start: {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 99
    },
    end: {
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 99
    }
}));


const BootstrapInput = withStyles((theme) => ({
    root: {
        boxShadow: 'none',
        // marginBottom: theme.spacing(2),
        'label + &': {
            marginTop: theme.spacing(2),
        },
        '&.noBorder input': {
            border: '1px solid #ffffff33',
            color: '#bbb'
        },
        '&.noBorder textarea': {
            border: '1px solid #ffffff33',
            color: '#bbb'
        },
        '&.MuiInputBase-multiline': {
            padding: 0
        }
    },
    input: {
        borderRadius: 4,
        color: '#fff',
        position: 'relative',
        backgroundColor: 'transparent',
        border: '1px solid #ffffff',
        fontSize: 16,
        padding: '6px 6px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderColor: '#f4b740',
        },
        '& input': {
            width: '100%'
        },
    }
}))(InputBase);

const FR_TextField = (props) => {
    const { id, name, label, isDisabled,
        className, labelClassName, autoComplete,
        startAdornment, endAdornment, value,
        endAdornmentInside, startAdornmentInside } = props;
    const classes = useStyles();


    return (
        <FormControl className='w-100 position-relative overflow-hidden rounded-2'>
            {label ?
                <InputLabel className={classes.label + ' ' + labelClassName} shrink htmlFor={id ? id : name}>
                    {label}
                </InputLabel> : <></>}
            {endAdornmentInside ?
                <div className={classes.end}>
                    {endAdornmentInside}
                </div> : <></>
            }
            {startAdornmentInside ?
                <div className={classes.start}>
                    {startAdornmentInside}
                </div> : <></>
            }
            <BootstrapInput
                autoComplete={autoComplete ? 'on' : 'off'}
                id={id ? id : name}
                startAdornment={startAdornment}
                endAdornment={endAdornment}
                value={value}
                {...props}
                className={(isDisabled ? 'noBorder' : '') + ' noArrow ' + className}></BootstrapInput>
        </FormControl>
    )
}

export default FR_TextField
