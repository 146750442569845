import React, { useState, useEffect } from 'react'
import { MDBCard, MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit'
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import SellYourProduct from './MyAuction/SellYourProduct';
import Assist from './Assist/Assist';

const useStyles = makeStyles((theme) => ({
    card: {
        background: 'linear-gradient(235.56deg, #45475F 0%, #28293D 75%), #3D3A44',
        borderRadius: '10px 10px 0px 0px'
    },
    header: {
        backgroundColor: 'none',
        padding: '0',
        marginBottom: '0',
    },
    boxPrice: {
        display: 'block',
        backgroundColor: '#3A3B4F',
        borderRadius: '4px',
        textAlign: 'center',
        fontSize: '0.9em',
        paddingTop: '5px',
        paddingBottom: '5px',
        color: '#fff',
        fontWeight: 'bold',
        '& small': {
            fontSize: '0.8em',
            color: '#ACB5C5',
        }
    },
    ActionBoxRow: {
        borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
        margin: '0',
        padding: '10px 0'
    },
    yellowText: {
        color: '#FFCD1E'
    },
    AuctionButton: {
        background: 'linear-gradient(90deg, #FF8800 10.41%, #FFCD1E 85.35%)',
        outline: 0,
        border: 0,
        borderRadius: '4px',
        border: '1px solid #FFCD1E',
        color: '#000',
        fontSize: '0.8em',
        padding: '5px 20px',
        fontWeight: '700',
        minWidth: '100px',
        '& i': {
            fontSize: '0.6em',
            marginLeft: '4px',
            paddingBottom: '3px',
            marginTop: '5px',
            float: 'right'
        }
    },
    AuctionButtonInfo: {
        background: 'linear-gradient(180deg, rgba(26, 227, 255, 0.8) -34.37%, rgba(24, 144, 255, 0) 125%), #1E85FF',
        outline: 0,
        border: 0,
        borderRadius: '4px',
        border: '1px solid #33B6FF',
        color: '#fff',
        fontSize: '1em',
        padding: '5px 15px',
        fontWeight: '500',
        width: '100%',
        '& i': {
            fontSize: '1em',
            marginRight: '4px'
        }
    },
    formGroup: {
        display: 'flex',
    },
    input: {
        width: '100%',
        background: '#1D1E2E',
        color: '#fff',
        borderRadius: '6px 0 0  6px',
        fontSize: '0.8em',
        padding: '5px 10px',
        border: 'none',
        fontWeight: 'normal',
        boxShadow: 'inset -1px -1px 1px 0px #fff5'
    },
    buttons: {
        background: 'linear-gradient(235.56deg, #45475F 0%, #28293D 102.93%), #3D3A44',
        border: '1px solid #3E434E',
        borderRadius: '100px',
        boxShadow: 'inset -1px -1px 4px #23253E, inset 2px 4px 8px #212236',
        padding: '3px 4px',
        display: 'flex',
    },
    miniButtons: {
        textAlign: 'center',
        fontSize: '0.6em',
        padding: '5px 2px',
        flex: '1',
        cursor: 'pointer',
        '&.active': {
            borderRadius: '100px',
            background: 'linear-gradient(235.56deg, #74768C 0%, #1D1E2E 102.93%), #28293D',
            boxShadow: '0px 2px 8px rgba(26, 28, 31, 0.79652)',
            border: '1px solid #5D5A64',
            padding: '3px 1px',
            cursor: 'default'
        }
    }
}));


const UserSection = (props) => {
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(0)

    return (
        <MDBCard className={classes.card + ' h-100'}>
            <div className='text-center py-3' style={{
                backgroundColor: '#00000033',
                borderBottom: '2px solid rgba(255, 255, 255, 0.18)'
            }}>
                <Avatar className='m-auto my-2' src="https://material-ui.com/static/images/avatar/1.jpg" />
                <h6 className={' text-white text-center mb-0'}>John Smith</h6>
            </div>
            <MDBRow className='mx-0 my-3'>
                <MDBCol size='12'>
                    <div className={classes.buttons}>
                        <small onClick={() => setActiveTab(0)} className={classes.miniButtons + `${activeTab == 0 ? ' active' : ''}`}>My Auction</small>
                        <small onClick={() => setActiveTab(1)} className={classes.miniButtons + `${activeTab == 1 ? ' active' : ''}`}>Assist</small>
                        <small onClick={() => setActiveTab(2)} className={classes.miniButtons + `${activeTab == 2 ? ' active' : ''}`}>Sold</small>
                        <small onClick={() => setActiveTab(3)} className={classes.miniButtons + `${activeTab == 3 ? ' active' : ''}`}>History</small>
                        <small onClick={() => setActiveTab(3)} className={classes.miniButtons + `${activeTab == 4 ? ' active' : ''}`}>Watch List</small>
                    </div>
                </MDBCol>
                <MDBCol size='12'>
                    {activeTab == 0 ? <SellYourProduct></SellYourProduct> : <></>}
                    {activeTab == 1 ? <Assist></Assist> : <></>}
                </MDBCol>
            </MDBRow>


        </MDBCard>
    )
}

export default UserSection
