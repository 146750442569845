import React, { useEffect, useState } from 'react'
import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import { Accordion, AccordionSummary, makeStyles, Typography, useTheme } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    mainImageContainer: {
        background: theme.gradient.primary
    },
    appBar: {
        backgroundColor: theme.palette.default.dark,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    Accordion: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        color: '#fff',
        marginBottom: '6px'
    },
    AccordionSummary: {
        background: 'linear-gradient(224.52deg, #3A3B4F 1.86%, #1D1E2E 98.99%)',
        boxShadow: '0px 4px 16px rgba(26, 28, 31, 0.79652)',
        borderRadius: '10px',
        border: '1px solid #626A6B',
        color: '#fff'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '80%',
        flexShrink: 0,
    },
}));


const ImageSlider = (props) => {
    const classes = useStyles();
    const { images, currentImageIndex } = props;

    return (<>

        {images && images.length > 0 ?
            <>
                <MDBRow className=''>
                    <MDBCol size='12' className='position-relative'>
                    </MDBCol>
                    <Carousel
                        // autoPlay
                        // cycleNavigation
                        animation='fade'
                        // onChange={(e) => console.log(e)}
                        timeout={{
                            appear: 0,
                            enter: 100,
                            exit: 0   // <-- Set this to 0
                        }}
                        index={currentImageIndex}
                        swipe={true}
                        indicators={false}
                        interval={5000}
                        navButtonsProps={{
                            style: {
                                opacity: 0.9,
                                backgroundColor: '#0002',
                                padding: '10px',    // 1
                                color: '#fff'    // 3
                            }
                        }}
                        navButtonsAlwaysInvisible={false}
                        navButtonsAlwaysVisible={true}>
                        {
                            images.map((image, i) =>
                                <div key={i} className='position-relative'>
                                    <img src='./logo/logoblack.svg' className='img-fluid w-100' />
                                    <img src={image.url} alt={image.title} className='img-fluid w-100 rounded-5 position-absolute left-0 right-0'></img>
                                </div>
                            )
                        }
                    </Carousel>
                </MDBRow>

                <MDBRow className='m-0'>
                    {/* <MDBCol size='12' className='p-0'>
                    <Accordion className={classes.Accordion} expanded={true}
                    // onChange={handleChange(tabTitle)}
                    >
                        <AccordionSummary
                            className={classes.AccordionSummary}
                            expandIcon={<ExpandMoreIcon className='text-white' />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header">
                            <Typography className={classes.heading}>Images</Typography>
                        </AccordionSummary>
                    </Accordion>

                </MDBCol> */}
                    <MDBCol size='12'>
                        <MDBRow className='mx-n1 pt-2 d-none'>
                            {
                                images.map((image, i) =>
                                    <SingleImage key={i} className='px-1' {...image}></SingleImage>
                                )
                            }
                            <SingleImage className='px-1 overflow-hidden' text='+120' {...images[5]}></SingleImage>
                        </MDBRow>
                    </MDBCol>
                </MDBRow></> : <></>
        }
    </>)
}

const SingleImage = (props) => {
    return <MDBCol
        className={props.className + ' cursor-pointer'}>
        <div className='position-relative w-100'>
            <img src={props.url} alt={props.title} className='img-fluid w-100 rounded-3'></img>
            {props.text ?
                <>
                    <div className='w-100 h-100 position-absolute bg-black opacity-60' style={{
                        top: 0,
                        left: 0
                    }}></div>
                    <span style={{
                        top: '30%',
                        left: '0',
                        width: '100%',
                    }} className='position-absolute text-center text-white'>{props.text}</span>
                </>
                : <></>}
        </div>

    </MDBCol>
}


export default ImageSlider
