const { default: axios } = require("axios");

const logout = (state, dispatch) => {
    axios.get(state.host + state.config.api.logout)
        .then((res) => {
            console.log(res.data)
            if (res.status == 200) {
                state.removeCookie('bearer');
                dispatch({ type: 'setAll', payload: {
                    login: false,
                    username: ''
                } });
                // setValues({ ...values, showMenu: false })
            }
        })
        .catch((err) => {
            console.error(err)
        })
}

module.exports = {
    logout: logout
}