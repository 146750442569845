import React, { useContext, useEffect, useState } from 'react'
import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import ThemeButton from '../../ThemeButton'
import FullPageModal from '../../FullPageModal/FullPageModal'
import { getBearer } from '../../../../helpers/publicHelpers'
import { Context } from '../../../../store'
import FR_TextField from '../../FormRenderer/FormComponents/FR_TextField'
import FR_Select from '../../FormRenderer/FormComponents/FR_Select'
import SortableImages from '../../../../components/SortableImages'
import ThemeAutoComplete from './CategoryAutoComplete'
import axios from 'axios'
import { withSnackbar } from 'notistack';
import { Divider, Typography } from '@material-ui/core'
import numeral from 'numeral'

const SellYourProduct = (props) => {
    const { enqueueSnackbar } = props;
    const [state, dispatch] = useContext(Context);

    const [values, setValues] = useState({
        auction: 'Select an Auction ...',
        category: '',
        subcategory: '',
        title: 'title',
        year: 2021,
        model: '',
        meter: '',
        price: '$ 0.00',
        description: '',
        images: []
    })

    const [auctions, setAuctions] = useState([
        { title: 'Online - October 2021', value: '1' },
        { title: 'Online - November 2021', value: '2' },
        { title: 'Online - December 2021', value: '3' },
    ])
    const [categories, setCategories] = useState([])
    const [subcategories, setSubCategories] = useState([])

    const [images, setImages] = useState([])

    useEffect(() => {
        setValues({
            ...values,
            images: images.map(i => i._id)
        })
    }, [images])

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        console.log(name, value)
        if (name == 'category') {
            setValues({
                ...values,
                subcategory: '',
                [name]: value
            })
        } if (name == 'price') {
            if (parseInt(numeral(value).format('0')) > 100000000) {
                enqueueSnackbar('Max Price is 100,000,000', {
                    variant: 'info'
                });
                value = 'AED ' + numeral(100000000).format('0,0')
            }
            else {
                value = 'AED ' + numeral(value).format('0,0')
            }
            setValues({
                ...values,
                [name]: value
            })
        } else {
            setValues({
                ...values,
                [name]: value
            })
        }
    }

    const [modal, setModal] = useState({
        open: false,
        // body: modalBody,
        title: 'Sell Your Product'
    })

    const handleSellProduct = () => {
        setModal({ ...modal, open: true })
    }

    const handleSubmit = (params) => {
        enqueueSnackbar('Submitting ...', {
            variant: 'info',
            autoHideDuration: 2000
        });
        axios.post(state.host + state.config.api.addLot, values, {
            headers: { 'x-auth-token': getBearer() }
        }).then(res => {
            res = res.data;
            if (res.success) {
                enqueueSnackbar('Submitted Successfully.', {
                    variant: 'success'
                });
            } else {
                enqueueSnackbar(res.result, {
                    variant: 'error'
                });
            }
        }).catch(err => {
            console.log(err)
            enqueueSnackbar('Error.', {
                variant: 'error'
            });
        })
    }

    useEffect(() => {
        try {
            const cats = state.config.data.lotCategories.map(x => ({ ...x, value: x.title }))
            setCategories(cats)
        } catch (error) {
            console.log(error)
        }
    }, [state.config])

    useEffect(() => {
        try {
            const subcats = state.config.data.lotCategories.filter(x => x.title == values.category)[0];
            console.log('*', values.category)
            console.log('**', subcats.subCategories.map(x => ({ ...x, value: x.title })))
            //.subCategories.map(x => ({...x, value : x._id}))
            setSubCategories(subcats.subCategories.map(x => ({ ...x, value: x.title })))
        } catch (error) {
            console.log(error)
        }
    }, [values.category])

    const [imageGrid, setImageGrid] = useState('1')
    return (<>
        <MDBRow>
            <h6 className={' text-white text-center mt-3 pt-2'}>Start Selling Your Products</h6>
            <MDBCol size='12'>
                <ThemeButton onClick={handleSellProduct} className='d-block w-100' type='submit'>Start your Auction</ThemeButton>
            </MDBCol>

        </MDBRow>

        <FullPageModal
            title={modal.title}
            setOpen={(e) => setModal({ ...modal, open: e })}
            open={modal.open}>
            <MDBRow className='m-0 mt-3'>
                <MDBCol size='12' lg='3' className='mb-3'>
                    <ThemeAutoComplete name='auction' label='Select Auction' options={auctions} onChange={handleChange}></ThemeAutoComplete>
                    {/* <small>More about this auction ...</small> */}
                </MDBCol>
                <MDBCol size='12' lg='3' className='mb-3'>
                    <ThemeAutoComplete name='category' label='Category' options={categories} onChange={handleChange}></ThemeAutoComplete>
                </MDBCol>
                <MDBCol size='12' lg='3' className='mb-3'>
                    <ThemeAutoComplete disabled={values.category == ''} name='subcategory' label='Sub Category' inputValue={values.subcategory} options={subcategories} onChange={handleChange}></ThemeAutoComplete>
                </MDBCol>
            </MDBRow>
            <MDBRow className='m-0'>
                <MDBCol size='12'>
                    <hr />
                </MDBCol>
                <MDBCol size='12' lg='3' className='mb-3'>
                    <FR_TextField label='Title' name='title' onChange={handleChange} type='text'></FR_TextField>
                </MDBCol>
                <MDBCol size='12' lg='3' className='mb-3'>
                    <FR_TextField label='Brand' name='brand' onChange={handleChange} type='text'></FR_TextField>
                </MDBCol>
                <MDBCol size='12' lg='3' className='mb-3'>
                    <FR_TextField label='Model' name='model' onChange={handleChange} type='text'></FR_TextField>
                </MDBCol>
                <MDBCol size='12' lg='3' className='mb-3'>
                    {/* <FR_Select id='year' label='Year' name='year' value={values.year} onChange={handleChange}>
                        {[...Array(50)].map((e, i) => <option key={i} value={2021 - i}>{2021 - i}</option>)}
                    </FR_Select> */}
                    <ThemeAutoComplete name='year' label='Year'
                        options={[...Array(50)].map((e, i) => ({ title: (2021 - i).toString(), value: (2021 - i).toString() }))}
                        onChange={handleChange}></ThemeAutoComplete>
                </MDBCol>
                <MDBCol size='12' lg='3' className='mb-3'>
                    <FR_TextField label='Meter' name='meter' onChange={handleChange} type='number'></FR_TextField>
                </MDBCol>
                <MDBCol size='12' lg='3' className='mb-3'>
                    <FR_TextField label='Reserverd Price' name='price' value={values.price} onChange={handleChange} type='text'></FR_TextField>
                </MDBCol>

            </MDBRow>
            <MDBRow className='m-0'>
                <MDBCol size='12'>
                    <hr />
                </MDBCol>
                <MDBCol size='12' className='pb-3'>
                    <Typography className='d-inline' variant='body1'>Photos {images.length}/100</Typography>
                    <Typography className='d-inline opacity-70 ps-2' variant='subtitle2'><small>Upload up to 100 photos.</small></Typography>
                </MDBCol>
                <MDBCol size='12'>
                    <SortableImages imageGrid={imageGrid} images={images} setImages={setImages}></SortableImages>
                </MDBCol>
            </MDBRow>
            <MDBRow className='m-0 justify-content-around'>
                <MDBCol size='12'>
                    <hr />
                </MDBCol>
                <MDBCol size='6' md='3' lg='2' xl='1'>
                    <ThemeButton onClick={handleSubmit} className='d-block w-100' type='submit'>Submit</ThemeButton>
                </MDBCol>
            </MDBRow>
            {/* <MDBRow className='m-0 justify-content-around opacity-10'>
                {JSON.stringify(values)}
            </MDBRow> */}
        </FullPageModal>
    </>)
}

export default withSnackbar(SellYourProduct)
