import React, { useState, useEffect } from 'react';
import { MDBIcon, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { makeStyles } from '@material-ui/core/styles';
import { Zoom, Grow } from '@material-ui/core';
import $ from 'jquery'
import axios from 'axios';
import FormField from './../../components/FormControls/FormField';
import validator from 'validator';
import _ from 'lodash'
import PIN from './PIN';
import ThemeButton from '../../auction/components/ThemeButton';

const useStyles = makeStyles(() => ({
    paper: {
        position: 'absolute',
        width: 300,
        backgroundColor: '#000',
        // border: '2px solid #666',
        boxShadow: '0 0 5px #fff',
        borderRadius: 6,
        color: '#ddd',
        padding: 10,
        outline: 'none'
    },
    code: {
        '& input': {
            textAlign: 'center',
            backgroundColor: 'transparent',
            color: '#fff',
            border: 'none',
            padding: 0,
            maxWidth: '25px',
            margin: '0px 4px !important',
            borderBottom: '2px solid #888',
            outline: 'none'
        },
        '& input:focus': {
            borderBottom: '2px solid #fff'
        }
    }
}));


const verify = (state, values, setValues, onSuccess) => {
    var postData = values;
    axios.post(state.host + state.config.api.signupVerify, postData)
        .then((res) => {
            const token = res.headers['x-auth-token'];
            res = res.data;
            if (res.success) {
                if (res.code == 200) {
                    setValues({
                        ...values,
                        mode: '',
                        signUpMode: '',
                        code: '',
                        message: {
                            style: 'success',
                            text: 'Verified'
                        }
                    })
                    onSuccess(token, res.result);
                }
            } else {
                console.log(res.result)
                setValues({
                    ...values,
                    message: {
                        style: 'danger',
                        text: res.result
                    }
                })
            }
        })
        .catch((err) => {
            console.error(err)
            if (err.response) {
            } else {
            }
        })
}
//const sendNewCode = (state, values, setValues) => {
//var postData = values;
// axios.post(state.host + state.config.api.sendNewCode, postData)
//     .then((res) => {
//         console.log('sendNewCode', res)
//         if (res.status == 200) {
//             //toastMessage({ open: true, severity: 'success', message: res.data })
//             //setMode(mode + '')
//             //setSignUpOpen(false)
//         }
//     })
//     .catch((err) => {
//         console.log('sendNewCode error', err)
//         if (err.response) {
//             //toastMessage({ open: true, severity: 'warning', message: err.response.data })
//         } else {
//             // toastMessage({ open: true, severity: 'warning', message: 'Error' })
//         }
//     })
//}

const signUpOptions = [
    {
        title: 'email',
        icon: 'envelope'
    },
    {
        title: 'mobile',
        icon: 'mobile-alt'
    },
    {
        title: 'telegram',
        icon: 'paper-plane'
    }]

const SignUpForm = (props) => {
    const { state, values, setValues, t, handleChange, onSuccess, signUpRequest } = props;
    const [showErrors, setShowErrors] = useState('d-none')



    const validateSignUpForm = () => {
        if (!values.accept) {
            return setValues({ ...values, message: { style: 'danger', text: 'Accept the terms.' } })
        }
        if (values.mode == 'email' && !validator.isEmail(values.email)) {
            return setValues({ ...values, message: { style: 'danger', text: 'Invalid Email' } })
        }
        if (values.mode == 'mobile' && !(validator.isMobilePhone(values.mobile) && validator.isLength(values.mobile, { min: 8, max: 15 }))) {
            return setValues({ ...values, message: { style: 'danger', text: 'Invalid Mobile' } })
        }
        if (values.mode !== 'password') {
            return setValues({ ...values, password: '', mode: 'password', message: { style: 'danger', text: '' } })
        }
        if (values.mode == 'password' && !validator.isLength(values.password, { min: 8, max: 30 })) {
            console.log(0)
            return setValues({ ...values, message: { style: 'danger', text: 'Password length should be 8 letters or more.' } })
        }
        console.log(values)
        // setValues({ ...values, message: { style: 'danger', text: '' } })
        signUpRequest()
    }

    useEffect(() => {
        console.log('&&')
        setShowErrors('d-none')
        setValues({ ...values, signupErrorMessage: false })
    }, [values.mode])

    const [showPassword, setShowPassword] = useState(false)

    return (
        <form id='SignUpForm' noValidate='noValidate' className='text-center pt-2' onSubmit={e => { e.preventDefault(); validateSignUpForm() }}>
            {values.mode == 'email' ?
                <>
                    <FormField autocomplete="off" className='my-3' handlechange={handleChange} type='email' label='Email' name='email' value={values.email} />
                    {/* <ErrorMessage showErrors={showErrors} message={state.$errors.email.map((data) => data.$message).join(",")}></ErrorMessage> */}
                </> :
                values.mode == 'mobile' ?
                    <>
                        <FormField className='my-3' handlechange={handleChange} label='Mobile' name='mobile' value={values.mobile} type='number' min={0} maxlength={12} />
                        {/* <ErrorMessage showErrors={showErrors} message={state.$errors.mobile.map((data) => data.$message).join(",")}></ErrorMessage> */}
                    </> :
                    values.mode == 'password' ?
                        <>
                            <FormField
                                endAdornmentInside={
                                    <MDBIcon onClick={() => setShowPassword(!showPassword)} className='mt-3 p-2' icon='eye'></MDBIcon>
                                }
                                className='my-3' handlechange={handleChange} type={showPassword ? 'text' : 'password'} label='Set Your Password' name='password' value={values.password} />
                            {values.signupErrorMessage ? <div className='text-danger pb-2'>{t('Sign Up error, try again later or contact support.')}</div> : <></>}
                            {/* <ErrorMessage showErrors={showErrors} message={state.$errors.password.map((data) => data.$message).join(",")}></ErrorMessage> */}
                        </> : values.mode == 'telegram' ? <>
                            Coming Soon ...
                        </> : <></>
            }
            {values.mode != '' ?
                <MDBRow>
                    <MDBCol size='12' className=''>
                        {values.signUpFormMessage}
                    </MDBCol>
                    {values.mode == 'email' || values.mode == 'mobile' ?
                        <MDBCol size='12' className='pt-1 mt-1'>
                            {/* <p className='small text-justify p-0 m-0' style={{
                                textAlign: 'justify'
                            }}>
                                By clicking this button you confirm that you have read and agree to the
                                <a target='_blank' className='px-1 text-neon-info d-inline-block glow-1' href={`/?view=termsofuse`}>
                                    Terms and Conditions
                                </a>
                                and
                                <a target='_blank' className='px-1 text-neon-info d-inline-block glow-1' href={`view=privacypolicy`}>
                                    Privacy Policy
                                </a>
                                of the company, consent to the processing of your personal information and confirm that you are of legal age.
                            </p> */}
                            <FormField handlechange={handleChange} label={`Accept Terms & Conditions`} name='accept' checked={values.accept} type='checkbox' />
                        </MDBCol> : <></>
                    }
                    <MDBCol size='3'></MDBCol>
                    <MDBCol size='6' className='text-center px-0'>
                        <ThemeButton variant='outlined' type='submit' disabled={!values.accept || values.mode == 'telegram'} className='bw-100'>
                            <span id='LoginButtonText'>
                                {values.waiting ? <MDBIcon spin icon='sync'></MDBIcon> : values.mode !== 'password' ? t('Sign Up') : t('Set Password')}
                            </span>
                        </ThemeButton>
                    </MDBCol>
                    <MDBCol size='3'></MDBCol>
                </MDBRow> : <></>
            }
        </form >

    )
}

const SignUp = (props) => {
    const { t, onSuccess, state } = props;
    const [values, setValues] = useState({
        _id: '',
        signUpMode: '',
        step: '',
        mode: '',
        mobile: '',
        email: '',
        code: '',
        referrer: state.query ? state.query.referrer : null,
        password: '',
        message: {
            style: 'info',
            text: ''
        },
        accept: false,
        loginFormMessage: '',
        passwordFormMessage: '',
        emailValid: false,
        mobileValid: false,
        passwordValid: false,
        toastMessage: props.toastMessage,
        dispatch: props.dispatch,
        setCookie: state.setCookie,
        waiting: false,
        signupErrorMessage: false
    })
    useEffect(() => {
        $('#SignUpForm input').attr('autocomplete', 'off');
        if (values.mode == 'Email') {
            $('#SignUpForm input[name=email]').trigger('focus')
        }
    }, [values.mode])

    const handleChange = (e) => {
        if (e.id) {
            setValues({
                ...values,
                [e.id]: e.value
            })
        } else {
            console.error('Set ID for input.')
        }
    }

    const signUpRequest = () => {
        //const { toastMessage } = values;
        var postData = _.pick(values, ['mobile', 'email', 'password', 'signUpMode', 'referrer']);
        axios.post(state.host + state.config.api.signup, postData)
            .then((res) => {
                res = res.data;
                console.log('res:', res.result)
                if (res.success) {
                    setValues({
                        ...values,
                        _id: res.result,
                        mode: 'Verification',
                        message: {
                            style: 'success',
                            text: ''
                        },
                        waiting: false
                    })
                } else {
                    console.log(res.result)
                    setValues({
                        ...values,
                        message: {
                            style: 'danger',
                            text: res.result
                        }
                    })
                }
            })
            .catch((err) => {
                console.error('err:', err)
                if (err.response) {
                    if (err.response.data == 'User exists.') {
                        // setResult(
                        //     <p className='text-danger'>
                        //         The user already exist. If you wish to login please
                        //         <strong onClick={() => switchToLogin(setSignUpOpen)} className='text-warning cursor-pointer ms-1'>click here.</strong>
                        //     </p>
                        // )
                    }
                    //toastMessage({ open: true, severity: 'warning', message: err.response.data })
                    setValues({
                        ...values,
                        waiting: false
                    })
                } else {
                    console.log('x')
                    setValues({
                        ...values,
                        waiting: false,
                        signupErrorMessage: true
                    })
                    // toastMessage({ open: true, severity: 'warning', message: 'Error' })
                }
            })
    }

    const resendCode = () => {
        setValues({
            ...values,
            message: {
                style: 'success',
                text: ''
            },
            waiting: true
        })
        setTimeout(() => {
            signUpRequest()
        }, 3000);
    }


    return (<>
        {values.mode !== 'Verification' ?
            <MDBRow className='pb-3 mx-0'>

                {/* {values.step == '' ? <> */}
                {signUpOptions.map((option, i) =>
                    <MDBCol size='4' className='text-center' key={i}>
                        <div onClick={() => setValues({ ...values, mode: option.title, signUpMode: option.title, message: { style: 'info', text: '' } })} className={`cursor-pointer ${values.mode == option.title ? 'text-neon-info glow-3' : ''}`}>
                            <MDBIcon size='2x' icon={option.icon}></MDBIcon>
                            <br />
                            {option.title}
                        </div>
                    </MDBCol>
                )}
                <SignUpForm
                    signUpRequest={signUpRequest}
                    state={state}
                    values={values}
                    setValues={setValues}
                    t={t}
                    onSuccess={onSuccess}
                    mode={values.mode}
                    handleChange={handleChange}></SignUpForm>
            </MDBRow>
            :
            <MDBRow className='m-0'>
                <MDBCol size='12' className='text-center'>
                    <Zoom in={true}>
                        {
                            !values.waiting ?
                                <div className='text-success pt-1'>
                                    <MDBIcon icon='check-circle' size='2x' color='success'></MDBIcon>
                                    <p className='py-2 m-0'>
                                        The code has been sent
                                    </p>
                                </div> :
                                <div className='text-warning pt-1'>
                                    <MDBIcon icon='sync' spin size='2x' color='warning'></MDBIcon>
                                    <p className='py-2 m-0'>
                                        Sending ...
                                    </p>
                                </div>
                        }
                    </Zoom>
                </MDBCol>
                <MDBCol size='12'>
                    <form id='VerifyForm' className='text-center' onSubmit={e => { e.preventDefault(); }}>
                        <small>Enter the code</small>
                        {/* replace with PIN */}
                        <PIN name='code' value={values.code} handlechange={handleChange} type='number' fields={6} />

                        {/* <ReactCodeInput value={values.code} className={classes.code + ' noArrow'} onChange={(v) => handleChange({ id: 'code', value: v })} type='number' fields={6} /> */}
                        <MDBRow className='justify-content-center pt-3'>
                            <MDBCol size='4' className='text-center px-0 pb-3'>
                                <ThemeButton variant='outlined' onClick={() => verify(state, values, setValues, onSuccess)} className='w-100'>{t('Verify')}</ThemeButton>
                            </MDBCol>
                            <MDBCol size='12'>
                                {/* <small className='cursor-pointer' onClick={() => sendNewCode(state, values, setValues)}> */}
                                <small className='cursor-pointer' onClick={() => resendCode()}>
                                    Didn't receive a code?
                                </small>
                            </MDBCol>
                        </MDBRow>
                    </form>
                </MDBCol>
            </MDBRow>

        }
        <MDBRow className='mx-0'>
            <MDBCol size='12' className='pb-3 pt-2 text-center d-block' style={{ minHeight: 30 }}>
                {
                    values.message.text != '' ?
                        <Grow in={true} timeout={1000}>
                            <div>
                                <small className={'text-neon-' + values.message.style}>{values.message.text}</small>
                            </div>
                        </Grow>
                        : <></>
                }
            </MDBCol>
        </MDBRow>
    </>
    )
}

export default SignUp
