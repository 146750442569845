import { makeStyles } from '@material-ui/core';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import React, { useState } from 'react'
import AuctionAccordion from '../Accordion/AuctionAccordion'
import ImageSlider from '../ImageSlider.js/ImageSlider'
const useStyles = makeStyles((theme) => ({

    lotDetails: {
        background: theme.gradient.primary,
        overflowX: 'hidden'
    }
}));
const LotDetails = (selectedLot) => {
    const classes = useStyles();
    const [currentImageIndex, setCurrentImageIndex] = useState(0)

    return (
        <MDBRow className={classes.lotDetails + ' m-0 h-100'}>
            <MDBCol className='px-1'>
                <ImageSlider currentImageIndex={currentImageIndex} {...selectedLot}></ImageSlider>
                <AuctionAccordion setCurrentImageIndex={setCurrentImageIndex} {...selectedLot}></AuctionAccordion>
            </MDBCol>
        </MDBRow>
    )
}

export default LotDetails
