import React, { createContext, useReducer } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from 'react-i18next';
import Reducer from './reducer';

const host = process.env.REACT_APP_HOST;
const initialState = {
    host: host,
    config: {
        recaptchaSiteKey: '6LdNkUocAAAAAMJP2dq9mep7urx1zJaoRGpaubP_',
        countryAccess: false,
        api: {
            getConfig: '/api/configs'
        },
        init: false,
        profile: {}
    },
    login: false
};

const Store = ({ children }) => {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [state, dispatch] = useReducer(Reducer, {
        ...initialState,
        t: t,
        i18n: i18n,
        cookies: cookies,
        setCookie: setCookie,
        removeCookie: removeCookie
    });
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
};

export const Context = createContext(initialState);
export default Store;