import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SideNav from './SideNav'
import HeaderMenu from './HeaderMenu';
import { Grow } from '@material-ui/core';
import { Context } from '../../store';
import { MDBIcon } from 'mdb-react-ui-kit';
import Home from '../../auction/Home';
import MainMenu from '../../auction/Layout/MainMenu';
import { Link } from 'react-router-dom';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        backgroundColor: theme.palette.default.dark,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        // marginRight: 36,
        color: theme.palette.default.contrastText
    },

    hide: {
        display: 'none',
    },

    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        color: theme.palette.default.contrastText,
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    grow: {
        flexGrow: 1,
    },
    logo: {
        height: '2rem',
        marginRight: '0.5rem'
    },
    logoText: theme.gradientText.secondary
}));

const LayoutAppBar = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [state, dispatch] = useContext(Context);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const [renderComponent, setRenderComponent] = useState(props.children)

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}>
                <Toolbar>
                    <IconButton
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx('d-md-none ' + classes.menuButton, {
                            [classes.hide]: open,
                        })}>
                        <MenuIcon />
                    </IconButton>
                    <Link to='/' onClick={() => setRenderComponent(<Home></Home>)}>
                        <img className={classes.logo} src='/logo/logo.svg' />
                    </Link>
                    <h4 className={'d-none m-0 d-md-flex '}>
                        <Link className={classes.logoText} to='/' onClick={() => setRenderComponent(<Home></Home>)}>
                            Continental Auction
                        </Link>
                    </h4>
                    {/* 
                    <Typography variant="subtitle1" className={classes.navItem}>
                        Bonus Auction
                    </Typography>
                    <Typography variant="strong" className={classes.navItem}>
                        Equipment Auction
                    </Typography>
                    <Typography variant="strong" className={classes.navItem}>
                        Shipment's
                    </Typography>
                    <Typography variant="strong" className={classes.navItem}>
                        Inline Transportation
                    </Typography>
                    <Typography variant="strong" className={classes.navItem}>
                        Mechanical Assists
                    </Typography> */}
                    <RenderTopMenu items={MainMenu}></RenderTopMenu>
                    <div className={classes.grow} />
                    <Grow in={true} timeout={3000}>
                        <div>
                            <HeaderMenu></HeaderMenu>
                        </div>
                    </Grow>
                </Toolbar>
            </AppBar>


            <SideNav state={state} renderComponent={renderComponent} setRenderComponent={setRenderComponent} drawerOpen={open} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen}></SideNav>


            <main className={classes.content}>
                <Toolbar></Toolbar>
                {renderComponent}
            </main>
        </div>
    );
}

const RenderTopMenu = (props) => {
    const classes = useStyles();
    const { items, setRenderComponent } = props;
    return (
        <>
            {items.length}
            {/* {items.map((item, i) => <Typography
            // onClick={() => setRenderComponent(item.component)}
            variant="strong" className={classes.navItem}>
                ${item.text}
            </Typography>)} */}
        </>
    )

}

const RenderSideMenu = (props) => {
    const classes = useStyles();

    const { items, setRenderComponent } = props;
    return (
        <>
            {items.map((item, i) => <Typography onClick={() => setRenderComponent(item.component)} variant="strong" className={classes.navItem}>
                ${item.text}
            </Typography>)}
        </>
    )

}

export default LayoutAppBar
