import React from 'react';
import Footer from './Footer';
import LayoutAppBar from './Header/LayoutAppBar';
import auctionTheme from './../auction/theme/Auction';
import { ThemeProvider } from '@material-ui/core/styles';
import BottomNav from './BottomNav';

const Layout = (props) => {
  return (<>
    <ThemeProvider theme={auctionTheme}>
      <LayoutAppBar>
        {props.children}
      </LayoutAppBar>
      <BottomNav></BottomNav>
      <Footer />
    </ThemeProvider>

    {/* <MDBContainer fluid>
        {props.children}
      </MDBContainer>*/}
  </>)
}

export default Layout
