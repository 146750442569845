import { makeStyles } from '@material-ui/core';
import { MDBCard } from 'mdb-react-ui-kit';
import React from 'react'
import CardHeaderSlider from '../Card/CardHeaderSlider';
import LotList from './UpcomingTab/LotList';

const useStyles = makeStyles((theme) => ({
    card: {
        background: 'linear-gradient(235.56deg, #45475F 0%, #28293D 75%), #3D3A44',
        borderRadius: '10px 10px 0px 0px',
        maxHeight: '100vh',
        overflow: 'scroll',
        position: 'relative'
    }
}));
const AuctionSection = (props) => {
    const classes = useStyles();

    const AuctionSectionTabs = [
        {
            title: 'UPCOMING',
            search: '',
            content: <LotList mode='' {...props}></LotList>
        },
        {
            title: 'MY BIDS',
            search: '',
            content: <LotList mode='Mybids' {...props}></LotList>
        },
        {
            title: 'OUTBIDS',
            search: '',
            content: <LotList mode='Outbids'  {...props}></LotList>
        }
    ]

    return (
        <MDBCard className={'noScrollbar pb-5 ' + classes.card}>
            <CardHeaderSlider tabs={AuctionSectionTabs}></CardHeaderSlider>
        </MDBCard>
    )
}

export default AuctionSection
