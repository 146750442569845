import React, { useState, useEffect } from "react";
import { MDBCol, MDBContainer, MDBFooter, MDBIcon, MDBRow } from "mdb-react-ui-kit";
// import { socket } from "../context/socket";
import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    title: theme.gradientText.secondary,
    socialIcon: {
        color: '#fff',
        // ...theme.gradientText.secondary,
        padding: '0.3rem',
        '&:hover' :{

            ...theme.gradientText.secondary,
        }
    },
    li: {
        '& a': {
            display: 'block',
            padding: '0.3rem 0',
            color: '#fff',
            transition: 'all 0.3s ease-in-out',
        },
        '& a *': {
            transition: 'all 0.3s ease-in-out',
        },
        '& a:hover': {
            paddingLeft: '0.5rem',
        },
        '&:hover span': {
            ...theme.gradientText.secondary,
        },
        '&:hover i':
            theme.gradientText.secondary,
    }
}));

const aboutus = [{
    text: 'Who We Are',
    icon: 'chevron-right'
}, {
    text: 'Affiliate Network',
    icon: 'chevron-right'
}, {
    text: 'Partnership Program',
    icon: 'chevron-right'
}, {
    text: 'Work With Us',
    icon: 'chevron-right'
}, {
    text: 'Contact Us',
    icon: 'chevron-right'
}]

const helpcenter = [{
    text: 'Get Started!',
    icon: 'chevron-right'
}, {
    text: 'Privacy Policy',
    icon: 'chevron-right'
}, {
    text: 'Heavy Machinary Auction',
    icon: 'chevron-right'
}, {
    text: 'Inline Transportations',
    icon: 'chevron-right'
}, {
    text: 'Mechanical Assist',
    icon: 'chevron-right'
}, {
    text: 'Shipments',
    icon: 'chevron-right'
},]

const services = [{
    text: 'Bonus Auction',
    icon: 'chevron-right'
}, {
    text: 'Equipments Auction',
    icon: 'chevron-right'
}, {
    text: 'Heavy Machinary Auction',
    icon: 'chevron-right'
}, {
    text: 'Inline Transportations',
    icon: 'chevron-right'
}, {
    text: 'Mechanical Assist',
    icon: 'chevron-right'
}, {
    text: 'Shipments',
    icon: 'chevron-right'
},]

const socials = [{
    text: 'Bonus Auction',
    icon: 'facebook',
    fab: true
}, {
    text: 'Equipments Auction',
    icon: 'instagram',
    fab: true
}, {
    text: 'Heavy Machinary Auction',
    icon: 'telegram-plane',
    fab: true
}, {
    text: 'Inline Transportations',
    icon: 'twitter',
    fab: true
}, {
    text: 'Mechanical Assist',
    icon: 'envelope'
},]

const Footer = () => {
    const theme = useTheme();
    const classes = useStyles(theme);

    // const [connected, setConnected] = useState(socket.connected)

    // useEffect(() => {
    //     // console.log('Proccess::', process)
    //     if (process) {
    //         // console.log('ENV::', process.env)
    //     }
    //     socket.on("disconnect", () => {
    //         setConnected('disconnected')
    //     });
    //     socket.on("connect", () => {
    //         setConnected('connected')
    //     });

    // }, [])

    return (
        <>
            <MDBFooter className='bg-black pt-5 pb-2 text-center text-light mt-3 small'>
                {/* <div className='text-center'>

                </div>

            </MDBFooter>
            <MDBFooter style={{
                bottom: 0,
                zIndex: 999,
                fontSize: 8,
                position: 'fixed'
            }} color="blue darken-4" className={`bg-black opacity-40 text-left w-100 page-footer font-small ${process.env.NODE_ENV === 'production' ? 'd-none' : ''}`}> */}
                <MDBContainer>

                    <MDBRow className='justify-content-center'>
                        <MDBCol md='6' lg='3' className='my-4 text-center font-weight-normal'>
                            <div className='px-5 px-md-4'>
                                <img src='/logo/logo.svg' className='img-fluid px-5 mb-3' alt='Auction' />
                                <h6 className={classes.title}>Continental Auction</h6>
                            </div>
                            <Socials classes={classes} items={socials}></Socials>
                        </MDBCol>
                        <FooterColumn classes={classes} items={aboutus} title='About Us'></FooterColumn>
                        <FooterColumn classes={classes} items={helpcenter} title='Help Center'></FooterColumn>
                        <FooterColumn classes={classes} items={services} title='Services'></FooterColumn>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol className='mt-2 py-2 font-weight-normal text-center'>
                            <MDBIcon icon='map-marker-alt' className='me-2'></MDBIcon>
                            P.O.Box 17553 Jebel Ali Freezone, Dubai, UAE
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol className='mt-2 mb-2 py-4 border-bottom border-top font-weight-normal text-center'>
                            <strong>BidAuction.com</strong> is operated by Continental Auctions FZCO Registered in Jebel Ali Freezone, Dubai, United Arab Emirates. Under License No 2341917 and Address Premises No. B56CR08, P.O.Box 17553 Jebel Ali Free Zone, Dubai, UAE.
                            Continental Auctions FZCO is duly licensed through government of Dubai JAFZA with
                            <br />
                            Auction Organizing 001-2341917-290721 of United Arab Emirates.
                        </MDBCol>
                        <MDBCol size='12' classes='text-small'>
                            Copyright 2021 - All Rights Reserved.
                        </MDBCol>
                    </MDBRow>

                </MDBContainer>

            </MDBFooter>
        </>
    );
}

const FooterList = (props) => {
    const { items, classes } = props;
    return (<>
        {items.map((item, i) =>
            <li className={classes.li} key={i}>
                <Link to="/Login">
                    <MDBIcon icon={item.icon} className='me-2'></MDBIcon>
                    <span>{item.text}</span>
                </Link>
            </li>
        )}
    </>)
}

const FooterColumn = (props) => {
    const { items, title, classes } = props;
    return (<>
        <MDBCol md='6' lg='3' className='my-3 font-weight-normal text-start text-md-left'>
            <div>
                <h5 className={classes.title + ' d-inline-block'}>{title}</h5>
            </div>
            <ul className="list-unstyled">
                <FooterList classes={classes} items={items}></FooterList>
            </ul>
        </MDBCol>
    </>)
}

const Socials = (props) => {
    const { items, title, classes } = props;
    return (<>
        <MDBRow>
            <MDBCol size='12' className='py-3 text-center' style={{ fontSize: '1.5em' }}>
                {items.map((item, i) =>
                    <a key={i} target='_blank' className={classes.socialIcon} href='#'>
                        <MDBIcon fab={item.fab} size='' icon={item.icon}></MDBIcon>
                    </a>
                )}
            </MDBCol>
        </MDBRow>
    </>)
}

export default Footer;