import React, { useEffect, useState } from 'react'
import { DropzoneArea, DropzoneDialog } from 'material-ui-dropzone'
import axios from 'axios'
import { getBearer } from '../../../../helpers/publicHelpers'
import { useContext } from 'react'
import { Context } from '../../../../store'
import { AddAPhoto, AttachFile, AudiotrackRounded, Description, PictureAsPdf, PlusOneRounded, Theaters } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core'
import { withSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    // Main Drop Zoone
    root: {
        background: '#0004',
        border: '1px solid #ccc',
        minHeight: '7rem',
        height: '100%'

    },
    textContainer: {
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        position: 'absolute'
    },
    text: {
        fontSize: '0.9rem',
        margin: 0
    },
    icon: {
        color: theme.palette.secondary.light
    }
}));
const useStylesSM = makeStyles((theme) => ({
    // Main Drop Zoone
    root: {
        background: '#0004',
        border: '1px solid #ccc',
        minHeight: '3rem',
        height: '100%'

    },
    textContainer: {
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        position: 'absolute',
        width: '100%',

    },
    text: {
        fontSize: '0.9rem',
        margin: 0,
        display: 'inline'
    },
    icon: {
        color: theme.palette.secondary.light,
        height: '2rem'
    }
}));

const FR_Dropzone = (props) => {
    // const theme = useTheme();
    const { filesList, setUplodedImages, enqueueSnackbar, closeSnackbar } = props;
    const classes = useStyles();
    const classesSM = useStylesSM();
    const [state, dispatch] = useContext(Context);
    const [files, setFiles] = useState([])

    const handleChange = (f) => {
        setFiles(f)
    }

    useEffect(() => {

        if (files.length >= 1) {
            let data = new FormData();
            for (let index = 0; index < files.length; index++) {
                data.append('files', files[index]);
            }
            const uploadSB = enqueueSnackbar('Uploading ...', {
                variant: 'info',
                persist: true
            });
            const headers = { 'x-auth-token': getBearer(), "Content-Type": "multipart/form-data" };
            axios.post(state.host + state.config.api.uploadFile, data, {
                headers: headers
            })
                .then(res => {
                    //  console.log(res);
                    closeSnackbar(uploadSB)
                    res = res.data;
                    if (res.success) {
                        setUplodedImages(res.result);
                        enqueueSnackbar('Uploaded Successfully.', {
                            variant: 'success',
                        });
                    } else {
                        enqueueSnackbar(res.result, {
                            variant: 'error',
                            autoHideDuration: 3000
                        });
                    }
                })
                .catch(err => {
                    closeSnackbar(uploadSB)
                    //  console.log(err);
                })
        }

    }, [files])

    const handlePreviewIcon = (fileObject, classes) => {
        const { type } = fileObject.file
        const iconProps = {
            className: classes.image,
        }

        if (type.startsWith("video/")) return <Theaters {...iconProps} />
        if (type.startsWith("audio/")) return <AudiotrackRounded {...iconProps} />

        switch (type) {
            case "application/msword":
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                return <Description {...iconProps} />
            case "application/pdf":
                return <PictureAsPdf {...iconProps} />
            default:
                return <AttachFile {...iconProps} />
        }
    }

    const initialFile = new File(["foo"], "foo.txt", {
        type: "text/plain",
    });

    return (
        <div className={props.className}>
            <DropzoneArea
                classes={props.imageGrid == '2' ? classes : classesSM}
                // showFileNames={true}
                // initialFiles={[initialFile]}
                // onAdd={(e) => console.log(e)}
                onDelete={(e) => console.log(e)}
                maxFileSize={10000000}
                onChange={(files) => handleChange(files)}
                // dropzoneText={"Drag and drop an image here or click"}
                dropzoneText={props.imageGrid == '2' ? 'Add Photos' : ''}
                acceptedFiles={['image/*']} // Accept only images
                filesLimit={100}
                multiple
                Icon={props.imageGrid == '2' ? AddAPhoto : AddAPhoto}
                //onDrop={(files) => handleChange(files)}
                // autoProcessQueue={false}
                // getPreviewIcon={handlePreviewIcon}
                //showPreviews={true}
                // acceptedFiles
                showAlerts={false}
                showPreviewsInDropzone={false}

            // useChipsForPreview={true}
            // previewGridProps={{container: { spacing: 1, direction: 'row' }}}
            // previewChipProps={{classes: { root: classes.previewChip } }}
            // previewText="Selected files"


            // {...props}
            />

            {/* <DropzoneDialog
                acceptedFiles={['image/*']}
                cancelButtonText={"cancel"}
                submitButtonText={"submit"}
                maxFileSize={5000000}
                open={false}
                // onClose={() => setOpen(false)}
                // onSave={(files) => {
                //     console.log('Files:', files);
                //     setOpen(false);
                // }}
                showPreviews={true}
                showFileNamesInPreview={true}
            /> */}


            {/* <MuiThemeProvider theme={theme}>
                <DropzoneArea maxFileSize={1} />
            </MuiThemeProvider> */}

        </div>
    )
}

export default withSnackbar(FR_Dropzone)
