import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0 !important',
        width: '100% !important',
        color: theme.palette.primary.contrastText,
        "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
            // Default transform is "translate(14px, 20px) scale(1)""
            // This lines up the label with the initial cursor position in the input
            // after changing its padding-left.
            transform: "translate(34px, 20px) scale(1);"
        },
        "& svg": { color: "#fff", marginTop: '-0.2rem' },
    },
    inputRoot: {
        color: theme.palette.primary.contrastText,
        fontSize: '0.9rem',
        padding: '0 !important',
        margin: '0 !important',
        // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
        '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
            // Default left padding is 6px
            padding: '4px 10px 6px 5px !important',
            margin: '0 !important',
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.contrastText,
            top: -3
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.contrastText
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.contrastText,
        },
        "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.contrastText,
            opacity: 0.5
        }
    },
    popper: {
        border: '1px solid #fff6'
    }
}));

const ThemeAutoComplete = (props) => {
    const classes = useStyles();
    const { onChange, label, options, name, ...otherProps } = props;
console.log(otherProps)
    return (
        <>
            <small>{label}</small>
            <Autocomplete
                classes={classes}
                disableClearable={true}
                options={options}
                getOptionLabel={(option) => option.title}
                onChange={(event, option) => {
                    onChange({ target: { name: name, value: option.value } });
                }}
                {...otherProps}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
        </>
    );
}

export default ThemeAutoComplete


