
import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import UpcomingSearch from '../AuctionSection/UpcomingTab/UpcomingSearch';
import LotList from '../AuctionSection/UpcomingTab/LotList';


const useStyles = makeStyles((theme) => ({
    root: {

    },
    containter: {

    },
    appBar: {
        position: 'sticky',
        top: 0,
        background: theme.gradient.primary,
        padding: 0,
        margin: 0,
        boxShadow: 'none',

        '& .MuiTabs-indicator': {
            backgroundColor: '#FFCD1E',
        },
        '& > div > div > div': {
            borderBottom: '2px solid rgba(255, 255, 255, 0.18)'
        }
    },
    tabsContainer: {
        backgroundColor: 'transparent'
    },
    tab: {
        color: '#fff',
        paddingRight: 0,
        paddingLeft: 0,
        minWidth: 0,
        '&.Mui-selected': {
            color: '#FFCD1E',
        }
    },

}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}



export default function CardHeaderSlider(props) {
    const { tabs } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    className={classes.tabsContainer}
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example">
                    {
                        tabs.map((tab, i) => <Tab className={classes.tab} key={i} label={tab.title} {...a11yProps(i)} />)
                    }
                </Tabs>
                {value == 0 || true ? <>
                    <UpcomingSearch></UpcomingSearch>
                </> : <></>}
            </AppBar>
            <div className={classes.containter}>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}>
                    {
                        tabs.map((tab, i) => <TabPanel key={i} value={value} index={i} dir={theme.direction}>
                            {tab.content}
                        </TabPanel>
                        )
                    }
                </SwipeableViews>
            </div>
        </div>
    );
}
