import React, { useState } from 'react'
import { MDBRow } from 'mdb-react-ui-kit'
import FullPageModal from './components/FullPageModal/FullPageModal'
import FR_Dropzone from './components/FormRenderer/FormComponents/FR_Dropzone'
import FormRenderer from './components/FormRenderer/FormRenderer'

const Auction = (props) => {

    return (
        <MDBRow className='pt-3'>
            <FullPageModal title='Profile'>
                <FormRenderer/>
            </FullPageModal>
        </MDBRow>
    )
}

export default Auction
