import { makeStyles, Modal, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import Lot from './Lot';
import FullPageModal from './../../FullPageModal/FullPageModal'
import LotDetails from '../LotDetails';

const useStyles = makeStyles((theme) => ({
    box: {
        padding: '5px',
        background: 'linear-gradient(235.56deg, #74768C 0%, #1D1E2E 102.93%), #28293D',
        boxShadow: '0px 4px 16px rgba(26, 28, 31, 0.79652)',
        transition: 'box-shadow 0.3s ease-in-out',
        borderRadius: '10px',
        margin: '1px',
    },
    selectedLotClass: {
        boxShadow: '0px 0px 8px #fff',
        border: '1px solid #fff',
        margin: '0px'
    },
    selectedLotClassMybids: {
        boxShadow: '0px 0px 8px #0f0',
        border: '1px solid #0f0',
        margin: '0px'
    },
    selectedLotClassOutbids: {
        boxShadow: '0px 0px 8px #f00',
        border: '1px solid #f00',
        margin: '0px',
        '& .LastBid': {
            color: '#f00 !important'
        }
    }
}));

const LotList = (props) => {
    const classes = useStyles();
    const { lots, selectedLot, setSelectedLot, mode } = props;
    const desktop = useMediaQuery('(min-width:600px)');

    const [modal, setModal] = useState({
        open: false,
        body: '',
        title: ''
    })

    useEffect(() => {
        setTimeout(() => {
            if (selectedLot._id) {
                if (!desktop) {
                    console.log('****')
                    setModal({
                        open: true,
                        body: <LotDetails {...selectedLot}></LotDetails>,
                        title: selectedLot.title
                    })
                }
            }
        }, 100);
    }, [selectedLot])

    useEffect(() => {
        setSelectedLot({})
    }, [])

    return (<>
        {
            lots.map((lot, i) =>
                <div key={i} className='mx- pb-3'>
                    <div className={classes.box + ' ' + (lot._id == selectedLot._id ? classes['selectedLotClass' + mode] : '')}>
                        <Lot selectedLot={selectedLot} setSelectedLot={setSelectedLot} {...lot}></Lot>
                    </div>
                </div>
            )
        }

        <FullPageModal
            className='d-md-none'
            title={modal.title}
            setOpen={(e) => { if (!e) setSelectedLot({}); setModal({ ...modal, open: e }) }}
            open={modal.open}>
            {modal.body}
        </FullPageModal>
    </>
    )
}

export default LotList
