import './styles/App.scss';
import React, { useEffect, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Context } from './store'
import { useCookies } from 'react-cookie';
import axios from 'axios';
import Layout from './Layout/Layout';
import { getBearer } from './helpers/publicHelpers';
import Test from './auction/Test'
import Home from './auction/Home'

function App() {
  const [state, dispatch] = useContext(Context);
  const [cookies, setCookie, removeCookie] = useCookies(['user']);

  const getConfig = () => {
    const headers = { 'x-auth-token': getBearer() };
    axios.get(state.host + state.config.api.getConfig, {
      headers: headers
    })
      .then((res) => {
        res = res.data;
        if (res.success) {
          console.log('res:', res)
          dispatch({
            type: 'setAll', payload: {
              config: res.result,
              login: res.result.login
            }
          })
          // if (!res.result.login) {
          //   removeCookie('username'); // remove bearer
          // }
        } else {
          console.error(res.result)
          dispatch({
            type: 'setAll', payload: {
              config: {
                ...state.config,
                countryAccess: false
              }
            }
          })
        }
      })
      .catch((err) => {
        console.log('getConfig Error:', err)
      })
  }

  // const validateToken = (token) => {
  //   const headers = { 'x-auth-token': token }
  //   axios.post(state.host + state.config.api.validateToken, null, {
  //     headers: headers
  //   })
  //     .then((res) => {
  //       const response = res.data;
  //       if (response.success) {
  //         dispatch({
  //           type: 'setAll', payload: {
  //             username: response.result.username,
  //             login: true
  //           }
  //         });
  //       } else {
  //         removeCookie('username');
  //         dispatch({
  //           type: 'setAll', payload: {
  //             username: '',
  //             login: false
  //           }
  //         })
  //       }
  //     })
  //     .catch((err) => {
  //       removeCookie('username');
  //       console.log('validateToken Error:', err)
  //       dispatch({
  //         type: 'setAll', payload: {
  //           username: '',
  //           login: false
  //         }
  //       })
  //     })
  // }

  useEffect(() => {
    console.log('App starts:', state)
    getConfig();
  }, [])

  useEffect(() => {
    console.log('state:', state)
  }, [state])

  return (
    <>
      {state ?
        <Router>
          <Switch>
            <Route path='/' exact>
              <Layout state={state}>
                <Switch>
                  <Route path='/' component={Home} />
                </Switch>
              </Layout>
            </Route>
            <Route path='/Test'>
              <Layout state={state}>
                <Switch>
                  <Route path='/' component={Test} />
                </Switch>
              </Layout>
            </Route>
          </Switch>
        </Router> : <></>
      }
    </>
  );
}
export default App;



