import React, { useContext, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import FormRendererControl from './FormRendererControl';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import ThemeButton from '../ThemeButton';
import { getBearer } from '../../../helpers/publicHelpers';
import axios from 'axios';
import { Context } from '../../../store';
import { withSnackbar } from 'notistack';

const FormRenderer = (props) => {
    const { formId, headers, title, submitButton, enqueueSnackbar } = props;
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const [state, dispatch] = useContext(Context);
    const [form, setForm] = useState()

    const getFormById = () => {
        axios.get(`${state.host}${state.config.api.getForm}/${formId}`, { headers: headers })
            .then((res) => {
                res = res.data;
                if (res.success) {
                    setForm(res.result)
                }
            })
            .catch((err) => {

            })
    }

    useEffect(() => {
        getFormById()
    }, [])

    const onSubmit = (data) => {
        const headers = { 'x-auth-token': getBearer() };
        axios.post(`${state.host}${form.api}`, data, {
            headers: headers
        })
            .then(res => {
                res = res.data;
                if (res.success) {
                    enqueueSnackbar('Done', {
                        variant: 'success',
                        autoHideDuration: 1000
                    });
                } else {
                    enqueueSnackbar(res.result, {
                        variant: 'error',
                        autoHideDuration: 3000
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar('Error', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            })
    };
    console.log('errors:', errors);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {
                form ?
                    <>
                        {title ? <h6>{form.title}</h6> : ''}
                        <MDBRow>
                            {form.fields.map((fieldSchema, i) =>
                                <MDBCol key={i} className={fieldSchema.className}>
                                    <Controller
                                        key={i}
                                        name={fieldSchema.name}
                                        control={control}
                                        defaultValue={fieldSchema.initialValue}
                                        render={({ field }) => <FormRendererControl {...field} {...fieldSchema} />}
                                    />
                                </MDBCol>
                            )}
                            <MDBCol size='12'>
                                {submitButton ? submitButton :
                                    <ThemeButton type='submit'>Submit</ThemeButton>
                                }
                            </MDBCol>
                        </MDBRow></> : <></>
            }


        </form>
    );

}


export default withSnackbar(FormRenderer)
