import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { MDBIcon } from 'mdb-react-ui-kit';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
        zIndex: 999,
        height: '3rem',
        background: theme.palette.primary.main,
        '& .Mui-selected i': theme.gradientText.secondary,
        '& .Mui-selected span': theme.gradientText.secondary,
    },
    action: {
        color: theme.palette.primary.contrastText,
        '&i.Mui-selected': {
            ...theme.gradientText.secondary,
        }
    }
}));

export default function BottomNav() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    return (
        <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            showLabels
            className={'d-sm-none ' + classes.root}>
            <BottomNavigationAction className={classes.action} label="Auctions" icon={<MDBIcon className='mb-1' icon='truck-monster'></MDBIcon>}></BottomNavigationAction>
            <BottomNavigationAction className={classes.action} label="Favorites" icon={<MDBIcon className='mb-1' icon='hammer'></MDBIcon>} />
            <BottomNavigationAction className={classes.action} label="Notifications" icon={<MDBIcon className='mb-1' icon='bell'></MDBIcon>} />
            <BottomNavigationAction className={classes.action} label="Watch List" icon={<MDBIcon className='mb-1' icon='heart'></MDBIcon>} />
        </BottomNavigation>
    );
}
