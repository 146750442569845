import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: 'transparent',
        overflowX: 'hidden'
    },

    Accordion: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        color: '#fff',
        marginBottom: '6px'
    },
    AccordionSummary: {
        background: 'linear-gradient(224.52deg, #3A3B4F 1.86%, #1D1E2E 98.99%)',
        boxShadow: '0px 4px 16px rgba(26, 28, 31, 0.79652)',
        borderRadius: '10px',
        border: '1px solid #626A6B',
        color: '#fff'
    },
    AccordionDetails: {
        backgroundColor: 'transparent',
        padding: '5px 0'
    },
    heading: {
        ...theme.gradientText.secondary,
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '80%',
        flexShrink: 0,

    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

const AuctionAccordion = (props) => {
    useEffect(() => {
        console.log('auctionacc')
    }, [])

    const classes = useStyles();
    const [expanded, setExpanded] = useState();

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        console.log('AuctionAccordion')
        setExpanded('General Information')
    }, [props.title])

    return (
        <div className={classes.root + ' mt-2'}>
            <AccordionItem {...props} tabTitle='Media Information' handleChange={handleChange} expanded={expanded}></AccordionItem>
            <AccordionItem {...props} tabTitle='General Information' handleChange={handleChange} expanded={expanded}></AccordionItem>
            <AccordionItem {...props} tabTitle='Technical Information' handleChange={handleChange} expanded={expanded}></AccordionItem>
            <AccordionItem {...props} tabTitle='Payment Information' handleChange={handleChange} expanded={expanded}></AccordionItem>
        </div>
    );
}

const AccordionItem = (props) => {
    const { expanded, handleChange, tabTitle, setCurrentImageIndex } = props;
    const classes = useStyles();

    return <Accordion className={classes.Accordion} expanded={expanded === tabTitle} onChange={handleChange(tabTitle)}>
        <AccordionSummary
            className={classes.AccordionSummary}
            expandIcon={<ExpandMoreIcon className='text-white' />}
            aria-controls="panel1bh-content"
            id="panel1bh-header">
            <Typography className={classes.heading}>{tabTitle}</Typography>
            {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
        </AccordionSummary>
        <AccordionDetails className={classes.AccordionDetails} >
            <MDBRow>
                <MDBCol size='12'>
                    {tabTitle == 'Media Information' && props.images ?
                        <MDBRow className='m-0'>
                            {
                                props.images.map((image, i) =>
                                    <SingleImage
                                        key={i}
                                        index={i}
                                        setCurrentImageIndex={setCurrentImageIndex}
                                        className='px-1' {...image}></SingleImage>
                                )
                            }
                        </MDBRow> : <></>
                    }
                    {tabTitle == 'General Information' ?
                        <>
                            <h6 className='py-2'>{props.title}</h6>

                            <div className='text-gray small pb-3'>
                                {props.general}
                            </div>
                            <div>
                                <div className='small pb-1'>
                                    <strong>ITEM NUMBER</strong>
                                    <span className='ms-3  font-weight-lighter'>4358760</span>
                                </div>
                                <div className='small pb-1'>
                                    <strong>LOCATION</strong>
                                    <span className='ms-3 font-weight-lighter'>Gorinchem, South Holland, Netherlands.</span>
                                </div>
                                <div className='small pb-1'>
                                    <strong>Starts From</strong>
                                    <span className='ms-3 font-weight-lighter'>US $80,000</span>
                                </div>
                                <div className='text-warning mt-3'>
                                    Click Here to Read FUll Description
                                </div>
                            </div>
                        </> :
                        tabTitle == 'Technical Information' ? <>
                            {props.technical}
                        </> : <></>
                    }
                    {tabTitle == 'Payment Information' ?
                        props.payment : <></>
                    }
                </MDBCol>
            </MDBRow>
        </AccordionDetails>
    </Accordion>

}

const SingleImage = (props) => {
    const { setCurrentImageIndex, index } = props;
    return <MDBCol size='3' onClick={() => setCurrentImageIndex(index)} className={props.className + ' px-1 pb-2 cursor-pointer'}>
        <div className='position-relative w-100'>
            <img src={props.url} alt={props.title} className='img-fluid w-100 rounded-3'></img>
            {props.text ?
                <>
                    <div className='w-100 h-100 position-absolute bg-black opacity-60' style={{
                        top: 0,
                        left: 0
                    }}></div>
                    <span style={{
                        top: '30%',
                        left: '0',
                        width: '100%',
                    }} className='position-absolute text-center text-white'>{props.text}</span>
                </>
                : <></>}
        </div>

    </MDBCol>
}


export default AuctionAccordion
