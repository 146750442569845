import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import { amber, yellow } from '@material-ui/core/colors';

const defaultColor = {
  main: '#000',
  dark: '#121318',
  light: '#333',
  contrastText: "#fff"
}

let theme = createTheme({
  // direction: 'rtl',
  gradient: {
    primary: 'linear-gradient(235.56deg, #45475F 0%, #28293D 75%), #3D3A44',
    secondary: 'linear-gradient(90deg, #FF8800 10.41%, #FFCD1E 85.35%)'
  },
  gradientText: {
    secondary: {
      background: 'linear-gradient(90deg, #FF8800 10.41%, #FFCD1E 85.35%)',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent'
    }
  },
  typography: {
    root: `font-family: "Lato", sans-serif;
    font-weight:900 !important;
    line-height: '1rem !important';
    color: #fff !important;
    `,
    h1: {

    },
    subtitle1: {

    },
    body1: {
      color: '#fff !important',
      fontWeight: 900
    }
    // `font-family: "Lato", sans-serif;
    // font-weight:900 !important;
    //     line-height: '1rem !important';
    // color: #ff0 !important;
    // `
    ,
    button: {
      fontWeight: 900
    },
  },
  palette: {
    focus: defaultColor.main,
    default: defaultColor,
    primary: {
      main: grey[900],
      dark: '#000',
      light: grey[500],
      contrastText: "#fff"
    },
    secondary: {
      main: amber[700],
      dark: amber[900],
      light: amber[500],
      contrastText: "#000"
    },
    tertiary: {
      main: yellow[700],
      dark: yellow[900],
      light: yellow[500],
      contrastText: "#000"
    }
  },
  overrides: {
    // Style sheet name ⚛️
    MuiPaper: {
      // Name of the rule
      root: {
        // Some CSS
        backgroundColor: defaultColor.dark,
        color: '#fff'
      },
    },
    // MuiFormControl: {
    //   display : 'none',
    //   '& svg': {
    //     fill: 'red',
    //   },
    // }
  },
});

// theme = responsiveFontSizes(theme, {
//   breakpoints: ['sm','md','lg','xl'],
//   factor: 20
// });


export default theme