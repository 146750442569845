import React, { useState, useEffect } from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { makeStyles } from '@material-ui/core/styles';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { Button, Fade, Grow } from '@material-ui/core';
import $ from 'jquery'
import FormField from '../../components/FormControls/FormField';
import ResendProgress from './ResendProgress'
import validator from 'validator';
import _ from 'lodash';
import PIN from './PIN';
import ThemeButton from '../../auction/components/ThemeButton';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 300,
        backgroundColor: '#000',
        // border: '2px solid #666',
        boxShadow: '0 0 5px #fff',
        borderRadius: 6,
        color: '#ddd',
        padding: 10,
        outline: 'none'
    }

}));

const handleError = (err, values, setValues) => {
    console.log('Error')
    console.log(err)
    if (err.response) {
        setValues({
            ...values,
            waiting: false,
            message: {
                style: 'danger',
                text: err.response.statusText
            }
        })
    } else {
        setValues({
            ...values,
            waiting: false,
            message: {
                style: 'danger',
                text: 'Connection error..'
            }
        })
    }
}

const Login = (props) => {
    const { t, onSuccess, state } = props;
    const [values, setValues] = useState({
        step: 1,
        loginMode: '',
        login: '',
        password: '',
        passwordMode: 'password',
        twofa: '-',
        waiting: false,
        enableResendOTP: true,
        message: {
            style: 'warning',
            text: ''
        }
    })

    const handleChange = (e) => {
        if (e.id) {
            setValues({
                ...values,
                [e.id]: e.value
            })
        } else {
            console.error('Set ID for input.')
        }
    }

    const loginSuccess = (token, result) => {
        setValues({
            ...values,
            waiting: false
        })
        onSuccess(token, result)
    }

    const ask2FA = (res) => {
        setValues({
            ...values,
            message: {
                style: 'success',
                text: '',
            },
            step: 3
        })
    }

    const waiting = (w) => {
        setValues({
            ...values,
            waiting: w
        })
    }

    const callLogin = () => {

        window.grecaptcha.ready(() => {
            window.grecaptcha
                .execute(state.config.recaptchaSiteKey, { action: "homepage" })
                .then(captcha => {
                    let postData = _.pick(values, ['loginMode', 'login', 'password', 'twofa'], 'passwordMode');
                    postData.captcha = captcha;
                    waiting(true);
                    axios.post(state.host + state.config.api.login, postData)
                        .then((res) => {
                            const token = res.headers['x-auth-token'];
                            res = res.data;
                            console.log('res', res)
                            if (res.success) {
                                // no 2FA
                                console.log('res.result', res.result)
                                if (res.code == 200) { loginSuccess(token, res.result); }
                                // ask for 2FA
                                else if (res.code == 403) { ask2FA(res); }
                            } else {
                                setValues({
                                    ...values,
                                    waiting: false,
                                    message: {
                                        style: 'danger',
                                        text: res.result
                                    }
                                })
                            }
                        })
                        .catch(err => handleError(err, values, setValues))
                })
        })

    }


    const callPasswordRecovery = () => {
        console.log(values)
        window.grecaptcha.ready(() => {
            window.grecaptcha
                .execute(state.config.recaptchaSiteKey, { action: "homepage" })
                .then(captcha => {
                    let postData = _.pick(values, ['loginMode', 'login', 'twofa']);
                    postData.captcha = captcha;
                    waiting(true);
                    axios.post(state.host + state.config.api.passwordrecovery, postData)
                        .then((res) => {
                            res = res.data;
                            if (res.success) {
                                // no 2FA
                                if (res.code == 200) {
                                    setValues({
                                        ...values,
                                        waiting: false,
                                        message: {
                                            style: 'info',
                                            text: res.result
                                        },
                                        step: 0
                                    })
                                }
                                // ask for 2FA
                                else if (res.code == 403) { ask2FA(res); }
                            } else {
                                setValues({
                                    ...values,
                                    waiting: false,
                                    message: {
                                        style: 'danger',
                                        text: res.result
                                    },
                                    step: 0
                                })
                            }
                        })
                        .catch(err => handleError(err, values, setValues))
                })
        })

    }


    const setStep = (s) => {
        setValues({
            ...values, step: s
        })
    }
    const setMessage = (style, text) => {
        console.log(style)
        console.log(text)
        setValues({
            ...values,
            message: {
                style: style,
                text: text
            }
        })
    }

    useEffect(() => {
        if (values.step >= 0 && values.step < 4) {
            $('input[name=login]').trigger('focus')
        }
        if (values.step == 2) {
            setValues({
                ...values,
                password: ''
            })
            $('input[name=password]').trigger('focus')
        }
        // Recovery
        if (values.step == 4) {
            callPasswordRecovery();
        }
    }, [values.step])
    return (
        <MDBRow className='m-0 pb-2'>
            <MDBCol size='12'>
                {values.step >= 0 ? <Step1 setMessage={setMessage} setStep={setStep} values={values} setValues={setValues} t={t} handleChange={handleChange}></Step1> : <></>}
                {values.step >= '2' && values.step != 4 ? <Step2 state={state} callLogin={callLogin} setMessage={setMessage} setStep={setStep} values={values} setValues={setValues} t={t} handleChange={handleChange}></Step2> : <></>}
            </MDBCol>
            <MDBCol size='12' className='pb-1 pt-2 text-center d-block =' style={{ minHeight: 30 }}>
                {values.message.text ?
                    <Grow in={true} timeout={1000}>
                        <div>
                            <small className={'text-' + values.message.style}>{values.message.text}</small>
                        </div>
                    </Grow>
                    : <></>}
            </MDBCol>
            <MDBCol size='12' className='text-center pt-0 small text-gray'>
                {values.step == 1 ? <small onClick={() => setValues({ ...values, step: 0, message: { text: '' } })} className='text-center cursor-pointer'>Forget Password?</small> :
                    <small onClick={() => setValues({ ...values, step: 1, message: { text: '' } })} className='text-center cursor-pointer text-neon-info'>Back to Login</small>
                }
            </MDBCol>

        </MDBRow>
    )
}

const Step1 = (props) => {
    const { setMessage, t, values, setValues, handleChange } = props;
    const validateStep = () => {
        const login = values.login;
        let loginMode = '';
        console.log(values)
        if (login.length < 5) return setMessage('danger', 'Invalid Login.');

        if (validator.isEmail(login)) {
            loginMode = 'email'
        } else if (validator.isMobilePhone(login) && validator.isLength(login, { min: 8, max: 10 })) {
            loginMode = 'mobile'
        } else if (!validator.contains(login, '@') && !validator.isNumeric(login)) {
            loginMode = 'username'
        } else {
            loginMode = 'error'
            return setValues({
                ...values,
                message: {
                    text: ''
                },
                loginMode: loginMode
            })
        }
        if (values.step > 0 && values.step != 4) {
            // Login Mode
            setValues({
                ...values,
                message: {
                    text: ''
                },
                loginMode: loginMode,
                step: 2
            })
        } else {
            // Recovery Mode
            setValues({
                ...values,
                message: {
                    text: ''
                },
                loginMode: loginMode,
                step: 4
            })
        }

    }
    return (<>
        <form onSubmit={e => { e.preventDefault(); /*validateSignUpForm()*/ }}>
            <MDBRow>
                <MDBCol size='12'>
                    {values.step <= 0 || values.step === 4 ? 
                    <span>Use your email or mobile to recover your password.</span> : <></>
                }
                </MDBCol>
                <MDBCol size='12'>
                    <FormField className='my-3'
                        endAdornmentInside={values.step > 1 ?
                            <div className='pt-3'>
                                <Button onClick={() => setValues({ ...values, step: 1, twofa: '-', message: { style: '', text: '' } })} className='text-white px-0 m-0'>
                                    <small>{t('Edit')}</small>
                                </Button>
                            </div> : <></>
                        }
                        disabled={values.step > 1} handlechange={handleChange} label={t('Telegram, Email or Phone')} name='login' value={values.login} />
                </MDBCol>
                <MDBCol size='4'></MDBCol>
                <MDBCol size='4' className='text-center px-0'>
                    {values.step == 1 ? <ThemeButton type='submit' variant='outlined' color='secondary' onClick={() => validateStep()} className='w-100'>{t('Next')}</ThemeButton> : <></>}
                    {values.step <= 0 || values.step == 4 ? <ThemeButton variant='outlined' color='secondary' waiting={values.waiting} onClick={() => validateStep()} className='w-100'>{t('Recover')}</ThemeButton> : <></>}

                </MDBCol>
                <MDBCol size='4'></MDBCol>
            </MDBRow>
        </form>
    </>)

}

const Step2 = (props) => {
    const { callLogin, setMessage, setStep, t, values, setValues, handleChange, state } = props;
    const classes = useStyles();

    //const [mode, setMode] = useState('password')
    const submit = () => {
        callLogin()
    }

    const useOTP = (params) => {
        setValues({
            ...values,
            password: '',
            passwordMode: 'otp',
            enableResendOTP: true,
            message: {
                style: 'success',
                text: ''
            },
            step: 2
        })
    }

    const requestOTP = () => {
        window.grecaptcha.ready(() => {
            window.grecaptcha
                .execute(state.config.recaptchaSiteKey, { action: "homepage" })
                .then(captcha => {
                    const postData = _.pick(values, ['loginMode', 'login']);
                    postData.captcha = captcha;
                    // waiting(true);
                    axios.post(state.host + 'api/users/requestotp', postData)
                        .then((res) => {
                            res = res.data;
                            if (res.success) {
                                setValues({
                                    ...values,
                                    password: '',
                                    enableResendOTP: false,
                                    passwordMode: 'otp',
                                    waiting: false,
                                    message: {
                                        style: 'success',
                                        text: res.result
                                    },
                                    step: 2
                                })

                            } else {
                                setValues({
                                    ...values,
                                    waiting: false,
                                    message: {
                                        style: 'danger',
                                        text: res.result
                                    }
                                })
                            }
                        })
                        .catch(err => handleError(err, values, setValues))
                })
        })

    }


    return (<>
        <form onSubmit={e => { e.preventDefault(); submit() /*validateSignUpForm()*/ }}>
            {
                values.step < 4 ?
                    <div>
                        <FormField className='my-3' handlechange={handleChange} name='password' disabled={values.step > 2}
                            label={values.passwordMode == 'otp' ? t('Submit the OTP Code') : t('Password')}
                            type={values.passwordMode == 'otp' ? 'number' : 'password'} value={values.password} />
                        {/* {mode == 'otp' ?
                <div className='text-center mb-2'>
                <small className='text-success'>The OTP code has been sent.</small>
                </div> : ''
            } */}
                    </div> : <></>
            }
            <MDBRow>
                {
                    values.step == 3 ?
                        <MDBCol size='12' className='text-center pb-3 mb-2'>
                            <div className='pb-2'>
                                <small> Enter 2FA Code:</small>
                            </div>
                            <PIN name='twofa' value={values.twofa} handlechange={handleChange} type='number' fields={6} />
                        </MDBCol> : <></>
                }
                <MDBCol size='4' className='pe-0'>
                    {
                        values.passwordMode == 'otp' ?
                            <small className='cursor-pointer' onClick={() => { setValues({ ...values, passwordMode: 'password', step: 2 }) }}>Password</small>
                            : <></>
                    }
                </MDBCol>
                <MDBCol size='4' className='text-center px-0'>
                    <Button color='secondary' variant='outlined' type='submit' style={{ width: 80, }}
                        className='w-100'>
                        <span id='LoginButtonText'>{values.waiting ? <MDBIcon icon='sync' spin></MDBIcon> : t('Login')}</span>
                    </Button>
                </MDBCol>
                {values.step < 4 ?
                    <MDBCol size='4' className='text-end'>
                        {
                            values.passwordMode == 'otp' ?
                                <>

                                    {values.enableResendOTP ?
                                        <small className='cursor-pointer' onClick={requestOTP}>Send</small> :
                                        <ResendProgress duration={5} callback={() => setValues({ ...values, enableResendOTP: true })}></ResendProgress>
                                    }
                                </>
                                :
                                <>
                                    {/* <small className='cursor-pointer' onClick={useOTP}>Use OTP</small> */}
                                </>
                        }
                    </MDBCol> : <></>
                }
            </MDBRow>
        </form>

    </>)
}

export default Login
