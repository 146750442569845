import React from 'react';
import { Checkbox, FormControl, FormControlLabel, InputLabel, makeStyles, MenuItem, Select, withStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

    label: {
        color: '#fff',
        '&.Mui-focused': {
            color: theme.palette.secondary.light + ' !important'
        },
    },
    formControl: {
        // '& svg': {
        //     fill: theme.palette.primary.contrastText
        // }
    },
    checkbox: {
        marginLeft: 5,
        color: theme.palette.secondary.light + ' !important',
        '&$checked': {
            // color: '#26ddff',
            // '& svg': {
            //     color: '#f00',
            // }
        },
        '& svg': {
            color: theme.palette.secondary.light + ' !important',
            // boxShadow: '0px 0px 5px #26ddff !important'

        }
    },
}));


const FR_Checkbox = (props) => {
    const { id, name, label, className, } = props;
    const classes = useStyles();


    return (
        <FormControl className={classes.formControl + ' ' + className + ' position-relative overflow-hidden rounded-2 mt-2'}>
            <FormControlLabel
                control={<Checkbox className={classes.checkbox} {...props} />}
                id={id ? id : name} label={label}
            />
        </FormControl>
    )
}

export default FR_Checkbox
