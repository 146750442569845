import React, { useEffect } from 'react';
import { Checkbox, createStyles, FormControl, FormControlLabel, InputLabel, makeStyles, withStyles } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';

{/*
    <FormControl className='w-100'>
                                <InputLabel shrink htmlFor="username">
                                    Username
                                </InputLabel>
                                <FormField
                                    id='username'
                                    startAdornment={
                                        <InputAdornment position="start" className='p-0 m-0 text-white'><MDBIcon icon='bars' className=' cursor-pointer pe-2'></MDBIcon></InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Button className='bg-success text-white'><MDBIcon icon='paper-plane' className='pe-2'></MDBIcon> Send</Button>
                                        </InputAdornment>
                                    }
                                    defaultValue="" />
                            </FormControl>
*/}

const useStyles = makeStyles((theme) => ({
    label: {
        color: '#fff',
        '&.Mui-focused': {
            color: '#f4b740 !important',
        },
    },
    start: {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 99
    },
    end: {
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 99
    },
    checkbox: {
        marginLeft: 5,
        color: '#26ddff',
        '&$checked': {
            // color: '#26ddff',
            // '& svg': {
            //     color: '#f00',
            // }
        },
        '& svg': {
            color: '#26ddff',
            // boxShadow: '0px 0px 5px #26ddff !important'

        }
    },
    checked: {},
}));


const BootstrapInput = withStyles((theme) => ({
    root: {
        boxShadow: 'none',
        // marginBottom: theme.spacing(2),
        'label + &': {
            marginTop: theme.spacing(2),
        },
        '&.noBorder input': {
            border: '1px solid #ffffff33',
            color: '#bbb'
        },
        '&.noBorder textarea': {
            border: '1px solid #ffffff33',
            color: '#bbb'
        },
        '&.MuiInputBase-multiline': {
            padding: 0
        }
    },
    input: {
        borderRadius: 4,
        color: '#fff',
        position: 'relative',
        backgroundColor: 'transparent',
        border: '1px solid #ffffff',
        fontSize: 16,
        padding: '6px 6px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderColor: '#f4b740',
        },
        '& input': {
            width: '100%'
        },
    }
}))(InputBase);

const FormField = (props) => {
    const { id, name, label,
        type, value, disabled, checked,
        className, style, labelClassName,
        multiline, rows, autoComplete,
        min, max,
        minlength, maxlength,
        startAdornment, endAdornment,
        endAdornmentInside, startAdornmentInside,
        handlechange, onBlur } = props;
    const classes = useStyles();

    const setValue = (e) => {
        if (!disabled) {
            const id = e.target.id ? e.target.id : e.target.name;
            let val = e.target.value;
            if (maxlength && val.length > maxlength) {
                console.log('error')
                val = val.substring(0, maxlength)
            }
            if (type == 'number') {
                if (min != undefined && val <= min) {
                    val = min;
                }
                if (max != undefined && val >= max) {
                    val = max;
                }
            }
            if (e.target.type == 'checkbox') {
                handlechange({ id: id, value: e.target.checked })
            } else {
                handlechange({ id: id, value: val })
            }
        }
    }


    return (
        <FormControl className='w-100 position-relative overflow-hidden rounded-2'>

            {type == 'checkbox' ?
                <>
                    <FormControlLabel
                        control={<Checkbox className={classes.checkbox} checked={checked} onChange={setValue} name={name} />}
                        id={id ? id : name} label={label}
                    />
                </> :
                <>
                    {label ?
                        <InputLabel className={classes.label + ' ' + labelClassName} shrink htmlFor={id ? id : name}>
                            {label}
                        </InputLabel> : <></>}
                    {endAdornmentInside ?
                        <div className={classes.end}>
                            {endAdornmentInside}
                        </div> : <></>
                    }
                    {startAdornmentInside ?
                        <div className={classes.start}>
                            {startAdornmentInside}
                        </div> : <></>
                    }
                    <BootstrapInput
                        id={id ? id : name}
                        value={value}
                        type={type}
                        // inputProps={{
                        //     min: min,
                        //     max: max,
                        //     maxlength: 4
                        // }}
                        autoComplete={autoComplete}
                        name={name}
                        startAdornment={startAdornment}
                        endAdornment={endAdornment}
                        multiline={multiline}
                        rows={rows}
                        style={style}
                        disabled={disabled}
                        className={(disabled ? 'noBorder' : '') + ' noArrow ' + className}
                        onBlur={onBlur}
                        onChange={setValue} ></BootstrapInput>

                </>
            }
        </FormControl>
    )
}

export default FormField
