import { makeStyles } from '@material-ui/core';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import React, { Component, useEffect, useState } from 'react';
import { Fragment } from 'react';
import { arrayMove, sortableContainer, sortableElement } from 'react-sortable-hoc';
import FR_Dropzone from '../auction/components/FormRenderer/FormComponents/FR_Dropzone';
import _ from 'lodash'
import FullPageModal from '../auction/components/FullPageModal/FullPageModal';
const IMAGE_BASE_URL = 'https://auction-api-server.azurewebsites.net'

const useStyles = makeStyles((theme) => ({
    sortableItem: {
        zIndex: 2000,
        minHeight: 150,
        [theme.breakpoints.up('md')]: {
            minHeight: 80
        },
        '&:hover i': {
            display: 'block',
        }
    },
    removeImage: {
        cursor: 'pointer',
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
}));


const SortableImages = (props) => {
    const { images, setImages, imageGrid } = props;
    const classes = useStyles();
    const [preview, setPreview] = useState()
    const [open, setOpen] = useState(false)
    const handleRemoveImage = (_id) => {
        setImages(_.filter(images, function (i) { return i._id !== _id; }))
    }

    const handlePreview = (url) => {
        setPreview(url)
        setOpen(true)
    }



    const SortableItem = sortableElement(image =>
        <MDBCol size='6' md='1' className={classes.sortableItem + ' mb-3'}>
            <div className='h-100 w-100 rounded-2 border border-light position-relative' style={{ zIndex: 9999, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url("${IMAGE_BASE_URL}${image.url}")` }}>
                <MDBIcon onClick={() => handleRemoveImage(image._id)} icon='times' className={classes.removeImage + ' text-danger float-end p-1 rounded-3'}></MDBIcon>
                <MDBIcon onClick={() => handlePreview(`${IMAGE_BASE_URL}${image.url}`)} icon='eye' className={classes.removeImage + ' text-warning float-start p-1 rounded-3 text-shadow-3'}></MDBIcon>
            </div>
        </MDBCol>
    );

    const SortableContainer = sortableContainer(({ children }) => {
        return <MDBRow>{children}</MDBRow>;
    });

    const onSortEnd = ({ oldIndex, newIndex, collection }) => {
        setImages(() => {
            let newCollections = [...images];

            newCollections = arrayMove(
                images,
                oldIndex,
                newIndex,
            );

            return newCollections;
        });

    };

    return (
        <>
            <SortableContainer
                pressThreshold={10}
                distance={10}
                axis='xy' onSortEnd={onSortEnd}>
                {images.map((item, i) => (
                    <SortableItem
                        key={i}
                        {...item}
                        index={i}
                        collection={0}
                    />
                ))}
                <MDBCol size='6' className='mb-3' lg={imageGrid}>
                    <FR_Dropzone imageGrid={imageGrid} className='h-100' setUplodedImages={setImages}></FR_Dropzone>
                </MDBCol>
            </SortableContainer>

            <FullPageModal
                title={'Image Preview'}
                setOpen={setOpen}
                open={open}>
                <MDBRow className='m-0'>
                    <MDBCol size='12'>
                        <img src={preview} className='img-fluid w-100' />
                    </MDBCol>
                </MDBRow>
            </FullPageModal>
        </>
    );
}

export default SortableImages

