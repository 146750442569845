import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      {/* <CircularProgress variant="determinate" {...props} /> */}
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        // position="absolute"
        marginLeft='10px'
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Typography variant="caption" component="div" color="textSecondary" className='text-white'>{`${Math.round(
          props.value,
        )}`}</Typography>
      </Box>
    </Box>
  );
}

const ResendProgress = (props) => {
  const [progress, setProgress] = useState(props.duration);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress <= 0 ? 0 : prevProgress - 1));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (progress <= 0) {
      props.callback()
    }

  }, [progress])

  return <CircularProgressWithLabel value={progress} color='#ff0' />;
}

export default ResendProgress
