const SideNavData = [
    // {
    //     text: 'Financial',
    //     icon: 'chart-bar',
    //     subItems: [{
    //         text: 'Table Stats',
    //         component: <TableStats></TableStats>
    //     },
    //     {
    //         text: 'Profit Stats',
    //         component: <ProfitStats></ProfitStats>
    //     },
    //     {
    //         text: 'Commission Stats',
    //         component: <CommissionStats></CommissionStats>
    //     },
    //     {
    //         text: 'Game Stats',
    //         component: <GameStats></GameStats>
    //     },
    //     {
    //         text: 'TAX Control',
    //         component: <TAX></TAX>
    //     },
    //     {
    //         text: 'Exchanges',
    //         component: <Exchanges></Exchanges>
    //     }]
    // }, {
    //     text: 'Users',
    //     icon: 'users',
    //     subItems: [{
    //         text: 'User List',
    //         component: <UserList></UserList>
    //     },
    //     {
    //         text: 'User Balances',
    //         component: <UserList mode='Balances'></UserList>
    //     },
    //     {
    //         text: 'User Logs',
    //         component: <UserLogs state={state} mode='Logs'></UserLogs>
    //     },
    //     {
    //         text: 'User Activities',
    //         component: <UserList mode='Activities'></UserList>
    //     },
    //     {
    //         text: 'User Groups',
    //         component: <UserGroups></UserGroups>
    //     },
    //     {
    //         text: 'Chat Logs',
    //         component: <UserList mode='Chat'></UserList>
    //     }]
    // }, {
    //     text: 'Deposits',
    //     icon: 'credit-card',
    //     subItems: [{
    //         text: 'Deposit List',
    //         component: <DepositList mode='Deposits'></DepositList>
    //     },
    //     {
    //         text: 'Deposit Gateways',
    //         component: <Gateways></Gateways>
    //     }]
    // }, {
    //     text: 'Withdraws',
    //     icon: 'upload',
    //     subItems: [{
    //         text: 'Withdraw List',
    //         component: <UserList mode='Deposits'></UserList>
    //     },
    //     {
    //         text: 'Withdraw Gateways',
    //         component: <Gateways></Gateways>
    //     }]
    // }, {
    //     text: 'Support',
    //     icon: 'life-ring',
    //     subItems: [{
    //         text: 'Tickets',
    //         component: <Tickets state={state}></Tickets>
    //     },
    //     {
    //         text: 'Templates',
    //         component: <Templates></Templates>
    //     },
    //     {
    //         text: 'System Tickets',
    //         component: <Tickets></Tickets>
    //     },
    //     {
    //         text: 'Announcement',
    //         component: <Announcements></Announcements>
    //     }]
    // },
    // {
    //     text: 'Risk Managements',
    //     icon: 'user',
    //     subItems: [{
    //         text: 'High Risk Users',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'High Risk Markets',
    //         component: <UserList></UserList>
    //         // }, {
    //         //     text: 'Domains',
    //         //     component: <UserList></UserList>
    //         // }, {
    //         //     text: 'Withdraws',
    //         //     component: <UserList></UserList>
    //         // }, {
    //         //     text: 'Affiliate Settings',
    //         //     component: <UserList></UserList>
    //         // }, {
    //         //     text: 'CPA Settings',
    //         //     component: <UserList></UserList>
    //     }]
    //     // Testimonial Link
    //     // Mapp
    //     // Backbone
    // }, {
    //     text: 'Casino Games',
    //     icon: 'gamepad',
    //     subItems: [{
    //         text: 'Crash Logs',
    //         component: <CrashLogs></CrashLogs>
    //     }]
    // },
    // {
    //     text: 'Affiliate',
    //     icon: 'user',
    //     subItems: [{
    //         text: 'Affiliate List',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Reports',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Domains',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Withdraws',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Affiliate Settings',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'CPA Settings',
    //         component: <UserList></UserList>
    //     }]
    //     // Testimonial Link
    //     // Mapp
    //     // Backbone
    // }, {
    //     text: 'Campaigns',
    //     icon: 'tag',
    //     subItems: [{
    //         text: 'URL Generator',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Referrers Panel',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'SEO Keywords',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'SEO Backlinks',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Google Analytics',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Email Marketing',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'SMS Marketing',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Telegram Bot',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'CRM Results',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Bonus Campaigns',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Forum',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Blog',
    //         component: <UserList></UserList>
    //     }]
    //     // Testimonial Link
    //     // Mapp
    //     // Backbone
    // }, {
    //     text: 'Online Shop',
    //     icon: 'shopping-basket',
    //     subItems: [{
    //         text: 'GamePlays',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Themes',
    //         component: <UserList></UserList>
    //     }]
    // }, {
    //     text: 'Settings',
    //     icon: 'cogs',
    //     subItems: [{
    //         text: 'Profile',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Employees List',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Employees Logs',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'KYC',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Domains',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Geo Management',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Currencies',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Cryptos',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'System',
    //         component: <SettingsSystem></SettingsSystem>
    //     }]
    // }, {
    //     text: 'Servers',
    //     icon: 'server',
    //     subItems: [{
    //         text: 'Servers List',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Servers Logs',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Proxy Servers',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Firewalls',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'IP Access',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Databases',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'CloudFlare',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Azure CDN',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Requests',
    //         component: <UserList></UserList>
    //     }, {
    //         text: 'Threads',
    //         component: <UserList></UserList>
    //     }]
    // }, {
    //     text: 'Reports Generator',
    //     icon: 'chart-line',
    //     subItems: []
    // }
]

export default SideNavData