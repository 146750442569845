import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import React from 'react'
import { getBearer } from '../../../../helpers/publicHelpers'
import FormRenderer from '../../FormRenderer/FormRenderer'
import ThemeButton from '../../ThemeButton'

const formRendererOptions = {
    formId: '6140690b8bc4bc46fc6464f2',
    headers: { 'x-auth-token': getBearer() },
    submitButton: <ThemeButton type='submit' className='w-25' type='submit'>Send</ThemeButton>
}

const Assist = (props) => {

    return (
        <MDBRow className='m-0'>
            <MDBCol size='12'>
                <FormRenderer {...formRendererOptions}></FormRenderer>
            </MDBCol>
        </MDBRow>
    )
}

export default Assist
