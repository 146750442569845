import React, { useState, useEffect } from 'react'
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit'
import { makeStyles } from '@material-ui/core/styles';

import numeral from 'numeral';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    card: {
        background: 'linear-gradient(235.56deg, #45475F 0%, #28293D 75%), #3D3A44',
        borderRadius: '10px 10px 0px 0px',
        maxHeight: '100vh',
        overflow: 'scroll',
        position: 'relative'
    },
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 300
    },
    title: {
        ...theme.gradientText.secondary,
        // color: theme.palette.secondary.main,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 'calc(80vw)'
    },
    lot: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        borderTopRightRadius: '6px',
        borderTopLeftRadius: '6px',
        // borderRadius: '1rem',
        textAlign: 'center',
    },
    description: {
        maxHeight: '3.5rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 'calc(55vw)',
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical'
    },
    location: {
        color: theme.palette.secondary.main,
    },
    lastBid: {

        color: theme.palette.secondary.main,
        color: '#0f0'// theme.palette.secondary.main,
    },
    header: {
        backgroundColor: 'none',
        padding: '0',
        marginBottom: '0',
    },
    boxPrice: {
        maxWidth: '100%',
        display: 'block',
        backgroundColor: '#3A3B4F',
        borderRadius: '4px',
        textAlign: 'center',
        fontSize: '1.2em',
        paddingTop: '8px',
        paddingBottom: '8px',
        color: '#fff',
        fontWeight: 'bold',
        '& small': {
            fontSize: '0.9em',
            color: '#ACB5C5',
        }
    },
    timer: {
        color: '#0f0',// theme.palette.secondary.main,
        minHeight: '1rem',
        display: 'inline-block'
    },
    ActionBoxRow: {
        // borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
        // margin: '0',
        // padding: '10px 0'
    },
    yellowText: {
        color: '#FFCD1E'
    },
    AuctionButton: {
        background: 'linear-gradient(90deg, #FF8800 10.41%, #FFCD1E 85.35%)',
        outline: 0,
        border: 0,
        borderRadius: '4px',
        border: '1px solid #FFCD1E',
        color: '#000',
        fontSize: '0.8em',
        padding: '5px 20px',
        fontWeight: 'bold',
        minWidth: '100px',
        '& i': {
            fontSize: '0.6em',
            marginLeft: '4px',
            paddingBottom: '3px',
            marginTop: '5px',
            float: 'right'
        }
    },
    AuctionButtonInfo: {
        background: 'linear-gradient(180deg, rgba(26, 227, 255, 0.8) -34.37%, rgba(24, 144, 255, 0) 125%), #1E85FF',
        outline: 0,
        border: 0,
        borderRadius: '4px',
        border: '1px solid #33B6FF',
        color: '#fff',
        fontSize: '1em',
        padding: '5px 15px',
        fontWeight: '500',
        width: '100%',
        '& i': {
            fontSize: '1em',
            marginRight: '4px'
        }
    },
    formGroup: {
        display: 'flex',
    },
    input: {
        width: '100%',
        background: '#1D1E2E',
        color: '#fff',
        borderRadius: '6px 0 0  6px',
        fontSize: '0.8em',
        padding: '5px 10px',
        border: 'none !important',
        outline: 'none',
        fontWeight: 'normal',
        boxShadow: 'inset -1px -1px 1px 0px #fff5',
    },
}));
const Lot = (props) => {
    const { title, image, lastBid, maxBid, setSelectedLot, selectedLot, time } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false)
    const [timer, setTimer] = useState()

    useEffect(() => {
        // setSelected(props.selected)
        if (selectedLot.title == props.title) {
            setOpen(!open)
        } else {
            setOpen(false)
        }
    }, [selectedLot])

    useEffect(() => {
        setInterval(() => {
            setTimer(time - Date.now())
        }, 1000);
    }, [])

    const handleSelect = () => {
        console.log(selectedLot)
        setSelectedLot(props)
    }


    return (
        <>
            <MDBRow onClick={handleSelect} className={'m-0 cursor-pointer overflow-hidden ' + classes.ActionBoxRow}>
                <MDBCol size='12' className='py-1 px-1'>
                    <div className={classes.title}>{title}</div>
                </MDBCol>
            </MDBRow>
            <MDBRow onClick={handleSelect} className='m-0 cursor-pointer pb-2' style={{
                backgroundColor: '#3A3B4F',
                borderRadius: 6,
            }}>
                <MDBCol size='4' className='pt-1 ps-0 pe-1'>
                    <div className={classes.lot + ' py-1 small'}>LOT 1</div>
                    <img src={image} className='img-fluid rounded'></img>
                </MDBCol>
                <MDBCol size='8' className='font-weight-bold' >
                    <MDBRow>
                        {/* <MDBCol size='12'>{title.substring(0,30) + (title.length > 30 ? '...' : '')}</MDBCol> */}
                        {/* <MDBCol size='12' style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>{title}</MDBCol> */}
                        <MDBCol size='12' className={classes.ellipsis + ' small px-0 pt-1 '}>
                            {/* <MDBIcon icon='hashtag' className={' me-1'}></MDBIcon> */}
                            <strong className='opacity-70'>Serial No:</strong>    <span > DF7DFG889D9F655DF</span>
                        </MDBCol>
                        <MDBCol size='12' className='small text-start  px-0'>
                            {/* <MDBIcon icon='tachometer-alt' className='me-1'></MDBIcon> */}
                            <strong className='opacity-70'>Meter Reads:</strong> <span> 8400 h</span>
                        </MDBCol>
                        <MDBCol size='12' className={classes.description + ' small px-0'}>
                            <strong className='opacity-70'>Description:</strong> <span> 3400 mm stick, A/C, Please click on the following link to see the short video: https://youtu.be/1I6so5K9q38</span>
                        </MDBCol>
                        <MDBCol size='6' className={classes.location + ' small pt-1 px-0'}>
                            <MDBIcon icon='map-marker-alt' className='me-1'></MDBIcon>
                            Dubai, UAE
                        </MDBCol>
                        <MDBCol size='6' className={classes.location + ' small pt-1  text-end'}>
                            <MDBIcon icon='heart' className='me-1 font-weight-lighter cursor-pointer'></MDBIcon>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>

            </MDBRow>
            <MDBRow onClick={handleSelect} className=' cursor-pointer' >
                {/* <MDBCol size='6'>
                    <MDBRow>
                        <MDBCol size='12' className='small pt-2'>
                            <MDBIcon icon='map-marker-alt' className='me-1'></MDBIcon>
                            Dubai, UAE
                        </MDBCol>
                        <MDBCol size='12' className={classes.yellowText + ' small'}>
                            <MDBIcon icon='stopwatch' className='me-2'></MDBIcon>
                            <span className={' font-weight-bold'}>
                                {timer ?
                                    <>
                                        {moment(timer).format('HH')}h | {moment(timer).format('mm')}m | {moment(timer).format('ss')}s
                                    </> : <></>
                                }
                            </span>
                        </MDBCol>
                    </MDBRow>
                </MDBCol> */}
                <MDBCol size='6' className='small pt-2 text-end'>
                    <span className={classes.boxPrice}>
                        <span className={classes.timer}>
                            {timer ?
                                <>
                                    {moment(timer).format('HH')}:{moment(timer).format('mm')}:{moment(timer).format('ss')}s
                                </> : <></>
                            }
                        </span>
                    </span>
                </MDBCol>
                <MDBCol size='6' className='small pt-2 text-end'>
                    <span className={classes.boxPrice}>
                        <span className={'LastBid ' + classes.lastBid}>
                            {/* <small>High Bid:</small>  */}
                            ${numeral(lastBid).format('0,0')}
                        </span>
                    </span>
                </MDBCol>
            </MDBRow>
            {/* <MDBRow className={classes.ActionBoxRow + ' ' + (open ? ' ' : ' border-0')}>

                <MDBCol size='6' className='small m-0 text-end'>
                    <button onClick={() => setSelected(props)} className={classes.AuctionButton}>BID NOW
                        <MDBIcon icon={open ? 'caret-up' : 'caret-down'}></MDBIcon>
                    </button>
                </MDBCol>
            </MDBRow> */}
            {
                true ?
                    <MDBRow className={classes.ActionBoxRow + ' py-2 border-0'}>
                        <MDBCol size='12' className={classes.yellowText + ' small'}>
                            <button className={classes.AuctionButtonInfo}>
                                <MDBIcon icon='bolt'></MDBIcon>
                                Quick BID
                                <span className='ps-2'>
                                    ${numeral(maxBid).format('0,0')}
                                </span>
                            </button>
                        </MDBCol>
                        <MDBCol size='12' className='m-0 text-end pt-2 small'>
                            <div className={classes.formGroup + ' noArrow'}>
                                <input placeholder='Max Bid Amount' type='number' className={classes.input + ' d-inline-block'}></input>
                                <button className={classes.AuctionButton} style={{
                                    borderRadius: '0 6px 6px 0',
                                    paddingLeft: '0',
                                    paddingRight: '0',
                                }}><div>MAX BID</div></button>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    : <></>
            }

        </>
    )
}

export default Lot
