const MainMenu = [{
    text: 'Continental Auction',
    reload: false,
    component: '',
},
{
    text: 'Bonus Auction',
    reload: false,
    component: '',
},
{
    text: 'Equipment Auction',
    reload: false,
    component: '',
},
{
    text: 'Shipments',
    reload: false,
    component: '',
},
{
    text: 'Inline Transportation',
    reload: false,
    component: '',
},
{
    text: 'Mechanical Assists',
    reload: false,
    component: '',
}]

module.exports = {
    MainMenu : MainMenu
}