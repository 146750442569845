import React from 'react'
import FR_Checkbox from './FormComponents/FR_Checkbox';
import FR_Dropzone from './FormComponents/FR_Dropzone';
import FR_Select from './FormComponents/FR_Select';
import FR_TextField from './FormComponents/FR_TextField';

const FormRendererControl = (props) => {
    const { component, ...componentProps } = props;
    return component == 'text-field' ?
        <FR_TextField {...componentProps}></FR_TextField> :
        component == 'select' ? <FR_Select {...componentProps}></FR_Select> :
            component == 'checkbox' ? <FR_Checkbox {...componentProps}></FR_Checkbox> :
                component == 'dropzone' ? <FR_Dropzone {...componentProps}></FR_Dropzone> :
                 <span>? {JSON.stringify(props)}</span>
}

export default FormRendererControl
